$white: white;
$black: #2b2e36;
$dark-1: #31384f;
$dark: #555e7a;

$salmon: #f1ddcc;
$red: #fb3449;
$copper: #d67c14;
$peach: #fff0e3;
$green: #13af28;
$pink: #f9f4fa;
$blue: #4d61aa;
$grey: #f0f2f1;

$text: #727a94;
$text: #696969;
$gray: #c8ccd7;
$lite: #e6e8ee;
$border: #d3d3d3;

$nav-a: $black;
$nav-fs: 13px;

:root {
  --white: white;
  --black: #1f303e;
  --text: #658499;
  --btn: #b3fcff;
  --btn-color: #1f303e;
  --hbg: #83fbff;
  --lite: #f2f7f7;
}

$textColours: (
  "dark": $dark,
  "text": $text,
  "lite": $lite,
  "black": $black,
  "blue": $blue,
  "green": $green,
  "red": $red,
  "copper": $copper,
);

@each $textColour, $i in $textColours {
  .text-#{$textColour} {
    color: $i !important;
  }
}

$bgColours: (
  "blue": $blue,
  "black": $black,
);

@each $bgColour, $i in $bgColours {
  .bg-#{$bgColour} {
    background-color: $i !important;
  }
}

// $border :  solid #D3D7E2;
$trans: cubic-bezier(0, 0, 0.2, 1);

@mixin icon($wh: 20px, $bg: help-icon) {
  display: inline-block;
  width: $wh;
  height: $wh;
  // background: url(../images/icons/#{$bg}.svg) no-repeat;
}

.icon-help {
  @include icon($wh: 31px);
  vertical-align: bottom;
}

@mixin blank($color: $dark) {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  color: $color;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
}

.blank-btn {
  @include blank;
}

.blank-blue {
  @include blank($color: $blue);
}

@mixin null-btn($mb: 0px) {
  background: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline-block;
  margin-bottom: $mb;
  cursor: pointer;
  transition: all 180ms $trans;

  &:hover {
    transform: scale(1.12);
  }

  &.colored {
    filter: grayscale(0);
  }
}

.null-btn {
  @include null-btn;
}

.readmore {
  background: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition: all 180ms $trans;
  text-decoration: underline;
  color: $red;
  font-weight: 600;
}

@mixin icon-btn {
  padding: 4px 10px;
  display: inline-block;
  text-align: center;
  background-color: white;
  border: 1px solid rgba(226, 230, 239, 0.34);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 70px;
  transition: all ease 220ms;
  transform: translateY(-0px);
  will-change: transform;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.11);
    transform: translateY(-2px);
  }
}

.icon-btn {
  @include icon-btn;
}

@mixin colors {
  &.black {
    color: $black !important;
  }

  &.grey {
    color: $grey !important;
  }

  &.text {
    color: $text !important;
  }

  &.orange {
    color: $orange !important;
  }

  &.pink {
    color: $pink !important;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

$margins: 0, 4, 6, 8, 10, 12, 16, 18, 20, 24, 28, 30, 32, 34, 36, 38, 40;

%margins {
  @each $margin in $margins {
    &.m#{$margin} {
      margin-bottom: #{$margin}px !important;
    }
    // margin-top: 0px;
  }
}

$spaces: (0, 2, 4, 6, 8, 10, 12, 16, 18, 10, 24, 28, 30, 32, 36, 38, 40);
$sides: (top, bottom, left, right);

@each $space in $spaces {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}#{$space} {
      margin-#{$side}: #{$space}px !important ;
    }

    .p#{str-slice($side, 0, 1)}#{$space} {
      padding-#{$side}: #{$space}px !important ;
    }
  }
}

@mixin img($ob: contain) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: $ob;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.flex-center {
  justify-content: center;
}

$fw: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $i in $fw {
  &.fw#{$i} {
    font-weight: #{$i} !important;
  }
}

$fs: 10, 12, 14, 16, 18, 20, 22, 24, 26;

@each $i in $fs {
  &.fs#{$i} {
    font-size: #{$i}px !important;
  }
}

$va: 1, 2, 3, 4, 0, -1, -2, -3, -4;

@each $i in $va {
  &.va#{$i} {
    vertical-align: #{$i}px !important;
  }
}

.lh12 {
  line-height: 1.2 !important;
}

.lh13 {
  line-height: 1.3 !important;
}

.lh14 {
  line-height: 1.5 !important;
}

.lh15 {
  line-height: 1.5 !important;
}

.lh16 {
  line-height: 1.6 !important;
}

img {
  max-width: 100%;
}

.btn-grp {
  display: flex;
  align-items: center;
  // flex-wrap: wrap;

  &.bg-grey {
    display: inline-flex;
    background: rgba(239, 239, 239, 0.5);
    border-radius: 10px;
    padding: 6px;

    a,
    button,
    div {
      margin-left: 10px;
      font-weight: 600;
    }
  }

  @include phone {
    flex-direction: column;
  }

  &.mt60 {
    margin-top: 60px;
  }

  a,
  button,
  div {
    margin-top: 10px;

    @include phone {
      width: auto;
    }

    @include phone-m {
      margin-left: 16px;
      margin-top: 0;
    }

    &:first-child {
      margin-left: 0;

      @include phone {
        margin-top: 0;
      }
    }
  }

  &.center {
    justify-content: center;
  }
}

@mixin ab {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@mixin img($ob: contain) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: $ob;
}

@mixin str {
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
  }
}

@mixin str-i {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

@mixin no-scrollbar {
  /* width */
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

@mixin updown {
  animation: updown 3000ms cubic-bezier(0.18, -0.03, 0.83, 1.02) 100ms infinite
    alternate;
  will-change: transform;
}

@mixin updown-sm {
  animation: updown-sm 2800ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02)
    infinite alternate;
  will-change: transform;
}

@mixin updown-lg {
  animation: updown-lg 3200ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02)
    infinite alternate;
  will-change: transform;
}

@mixin updown-xl {
  animation: updown-xl 4000ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02)
    infinite alternate;
  will-change: transform;
}

.updown {
  @include updown;
}

@keyframes updown {
  0% {
    transform: translate3d(0, 5px, 0);
  }

  50% {
    transform: translate3d(0, -3px, 0);
  }

  100% {
    transform: translate3d(0, 5px, 0);
  }
}

.updown-sm {
  @include updown-sm;
}

@keyframes updown-sm {
  0% {
    transform: translate3d(0, -3px, 0);
  }

  50% {
    transform: translate3d(0, 1px, 0);
  }

  100% {
    transform: translate3d(0, -3px, 0);
  }
}

.updown-lg {
  @include updown-lg;
}

@keyframes updown-lg {
  0% {
    transform: translate3d(0, -6px, 0);
  }

  50% {
    transform: translate3d(0, 3px, 0);
  }

  100% {
    transform: translate3d(0, -6px, 0);
  }
}

.updown-xl {
  @include updown-xl;
}

@keyframes updown-xl {
  0% {
    transform: translate3d(0, 7px, 0);
  }

  50% {
    transform: translate3d(0, -4px, 0);
  }

  100% {
    transform: translate3d(0, 7px, 0);
  }
}

@mixin loader-circle {
  content: "";
  position: relative;
  border-radius: 50%;
  border: 4px solid $black;
  border-right-color: transparent;
  animation: spin 600ms linear infinite forwards;
  width: 28px;
  height: 28px;
  z-index: 10;
  display: inline-block;
}

.loader-cicle {
  @include loader-circle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.ml-div {
  margin-left: 0;
  margin-top: 10px;

  @include tabv-m {
    margin-left: auto;
    margin-top: 0px;
  }
}

@mixin checkbox {
  position: relative;

  label {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 16px;
    color: $dark;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    &:before {
      content: "";
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid #e8e7e9;
      margin-right: 14px;
      background-color: white;
      overflow: hidden;
      bottom: 2px;
    }
  }

  input {
    @include str-i;
    margin: 0;

    &:checked + label {
      &:before {
        background-color: $orange;
      }
    }
  }
}

@mixin ul {
  position: relative;

  li {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 22px;
    color: $gray;

    &:before {
      content: "";
      position: relative;
      display: inline-block;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      background-color: $orange;
      border-radius: 50%;
      flex-shrink: 0;
      top: 3px;
    }
  }
}

.h300 {
  height: 300px;
}
figure {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

[type="time"] {
  position: relative;
  &::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    left: 0;
    background: #0000;
    position: absolute;
    // transform: scale(12)
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="file"],
input[type="date"],
input[type="time"],
textarea,
.MuiSelect-select,
select,
.MuiInputBase-multiline {
  width: 100%;
  background: transparent;
  border: 1px solid #d7d3d3;
  border-radius: 4px;
  // border: 1px solid transparent;
  // border: 1px solid $text;
  color: $black;
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  min-height: auto !important;
  $open: 'Open Sans', sans-serif;
  box-sizing: border-box;
  height: auto;

  @include phone-m {
    font-size: 16px;
    padding: 11px 15px;
    line-height: 1.38;
  }

  &.error {
    border-color: $red;
  }

  &::placeholder {
    color: #8992a9;
  }

  &:focus {
    outline: none;
    border-color: $blue;
    // box-shadow: 0px 0px 0px 3px rgba($red, 0.04);
  }

  &.error {
    border-color: $red;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    background-color: whitesmoke;
  }

  &.sm-input{
    padding: 4px;
    width: auto;
    font-size: 14px;
  
    @include phone-m{
      padding: 5px 4px 4px;
      width: auto;
      font-size: 14px;
    }
  }
}

select {
  width: 100%;
  background: transparent;
  background: transparent url(../images/icons/caret-down-icon.svg) no-repeat;
  background-position: calc(100% - 14px) 20px;
  appearance: none;

  &::placeholder {
    color: $text;
  }

  &:focus {
    outline: none;
    // box-shadow: 0px 0px 0px 3px rgba($red, 0.04);
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type="date"],
input[type="time"] {
  padding: 11px 10px;
}

textarea {
  border: 1px solid #9da3b6;
  padding: 14px 14px 14px;
  line-height: 1.4;
}



.date-input {
  input {
    background: white url(../images/icons/calendar-red-icon.svg) no-repeat;
    background-position: calc(100% - 12px) center;
  }

  >.react-datepicker-wrapper{
    min-width: 160px;
  }
}

.form-in {
  position: relative;
  text-align: left;

  .MuiInput-formControl {
    margin-top: 0;
  }

  .MuiTextField-root {
    width: 100%;
  }

  [class*="container"] {
    [class*="control"] {
      min-height: 46px;
      box-shadow: none;

      [class*="indicatorContainer"] {
        &:last-child {
          svg {
            opacity: 0;
          }
          background: url(../images/icons/caret-down-icon.svg) no-repeat center;
        }
      }

      &:hover,
      &:focus {
        border-color: $blue;
      }

      > div:first-child,
      [class*="ValueContainer"] {
        padding: 2px 15px;
        font-size: 14px;
        font-size: 16px;
        // padding: 14px 15px;
        line-height: 1.38;
      }
    }
    [class*="menu"] {
      font-size: 12px;
    }
    [class*="indicatorSeparator"] {
      display: none;
    }
  }

  &.date-pick {
    label {
      transform: translate(14px, 17px) scale(1);
      font-size: 14px;
      z-index: 2;
      top: 0;
      left: 0;
      position: absolute;

      &.Mui-focused,
      &.MuiInputLabel-shrink {
        transform: translate(12px, -5px) scale(0.8);
        background-color: white;
      }
    }
  }

  > label {
    font-size: 12px;
    margin-bottom: 6px;
    display: block;
    color: $text;
  }

  fieldset {
    border: none;
  }

  p.Mui-error {
    margin: 3px 0 0;
  }

  .MuiInputBase-formControl {
    line-height: 1;
  }

  &.phone {
    .f-in {
      display: flex;

      .react-tel-input {
        select,
        input {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          min-width: 80px;
          height: auto;
          background: url(../images/icons/caret-down-icon.svg) no-repeat;
          background-position: calc(100% - 12px) 19px;
          line-height: 1;
          padding: 13px 14px;
        }

        .flag-dropdown {
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 10;
          background-color: transparent;
        }

        .selected-flag {
          opacity: 0;
          width: 100%;
        }
      }

      > div {
        &:nth-child(2) {
          max-width: 120px;
          padding-right: 10px;
        }
      }
      > .MuiTextField-root {
        input {
          padding-left: 16px !important;
        }
      }

      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .code {
      max-width: 130px;
      padding-right: 10px;
    }

    .pl {
      padding-left: 12px;
    }
  }

  .MuiSelect-select {
    border: 1px solid #d7d3d3;
  }

  &.grey {
    input,
    .MuiSelect-select,
    .MuiInputBase-multiline {
      background-color: #f1eded;

      textarea {
        background-color: transparent;
      }
    }

    [class*="container"] {
      [class*="control"] {
        background-color: #f1eded;
      }
    }
  }

  &.icon {
    .icon-left {
      position: absolute;
      top: 12px;
      left: 8px;
      z-index: 10;

      @include phonev-m {
        left: 10px;
      }

      @include phone-m {
        top: 10px;
      }
    }

    input,
    .MuiSelect-select,
    .MuiInputBase-multiline {
      padding-left: 38px;

      @include phonev-m {
        padding-left: 40px;
      }
    }

    .f-in {
      label {
        &:not(.Mui-focused) {
          transform: translate(42px, 14px) scale(1) !important;

          // @include phonev-m{
          //   transform: translate(42px, 14px) scale(1) !important;
          // }
        }

        &.MuiInputLabel-shrink,
        &.MuiFormLabel-filled {
          transform: translate(14px, -7px) scale(0.6) !important;
          background-color: $peach;
          padding: 0px 4px;
        }
      }
    }

    [class*="container"] {
      [class*="control"] {
        opacity: 1;
        [class*="ValueContainer"], >div:first-child {
          padding-left: 40px;
        }
      }
    }
  }

  &.right-icon {
    input {
      padding-right: 40px;
    }
  }

  &:has(.icon-right){
    input{
      padding-right: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon-right {
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all $trans 180ms;

    &.text {
      font-size: 14px;
      top: 14px;
      right: 14px;
    }

    .MuiIconButton-root {
      padding: 0;
    }

    @include phone-m {
      top: 12px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .f-in {
    position: relative;

    &.otp {
      display: flex;
      margin: 0 -10px;
      justify-content: center;

      input {
        width: 60px;
        margin: 0 10px;
        text-align: center;
        padding: 14px 10px;
      }
    }

    .MuiFormControl-root {
      width: 100%;
    }

    label {
      font-size: 14px;
      color: $text;
      margin-bottom: 8px;
      $open: 'Open Sans', sans-serif;
      // transform: translate(14px, 14px) scale(1);

      @include phone-m {
        font-size: 14px;
      }

      &:not(.Mui-focused) {
        transform: translate(14px, 15px) scale(1);

        // @include phone-m{
        //   transform: translate(14px, 15px) scale(1);
        // }
      }

      &.MuiInputLabel-shrink,
      &.MuiFormLabel-filled {
        transform: translate(14px, -8px) scale(0.6) !important;
        background-color: $peach;
        padding: 0px 4px;
      }
    }
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $blue;
    }
  }

  &.error {
    input,
    select,
    textarea {
      border-color: $red;
    }

    .error-msg {
      display: block;
    }
  }

  &.icon .custom-label:not(.Mui-focused) {
    position: absolute;
    background-color: white;
    padding: 0px 4px;
    // font-size: 16px;
    top: -11px;
    z-index: 2;
    left: -9px;
    transform: scale(0.6) !important;
    color: $text;
  }
}

.date-pick {
  input {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1.15;
  }
}

.error-msg {
  // display: none;
  color: $red;
  font-size: 14px;
  margin-top: 4px;
}

.MuiInputBase-root {
  &.error {
    input {
      border-color: $red;
    }
  }
}

.MuiInput-underline {
  &:before,
  &:after {
    display: none;
  }
}

.otpContainer {
  .otp-input {
    input {
      min-width: 50px;
      font-size: 16px;
    }
  }
}

.MuiSwitch-sizeMedium.MuiSwitch-root {
  position: relative;
  height: auto;
  width: auto;
  padding: 0px;
  box-shadow: 3px 2px 6px rgba(6, 6, 6, 0.12%);
  border-radius: 50px;
  // box-shadow: 3px 2px 6px rgba(6, 6, 6, 0.12);
  // background-color: whitesmoke;

  .MuiSwitch-switchBase {
    position: absolute;
    padding: 2.6px;
    background-color: transparent;

    .MuiSwitch-thumb {
      background-color: #696969;
    }

    &.Mui-checked {
      .MuiSwitch-thumb {
        background-color: white;
      }

      & + .MuiSwitch-track {
        background-color: $blue;
        opacity: 1;
      }
    }
  }

  .MuiSwitch-track {
    height: 25px;
    width: 46px;
    padding: 0px;
    border-radius: 50px;
    background-color: whitesmoke;
    left: 0;
    display: inline-block;
    opacity: 1;
    // margin-left: -24px;
  }
}

.switch {
  position: relative;
  display: inline-block;

  > div {
    display: flex;
    align-items: center;

    label {
      font-weight: 500;
      padding-left: 8px;
      margin-bottom: 0px;
      font-size: 14px;
    }
  }

  .slide {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 46px;
    height: 24px;
    background: rgba(211, 211, 211, 0.565);
    border-radius: 82px;
    padding: 3px;
    transition: all ease 200ms;

    &:before {
      content: "";
      display: inline-block;
      position: relative;
      width: 18px;
      height: 18px;
      background: #696969;
      box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.08);
      border-radius: 82px;
      transition: all ease 200ms;
    }
  }

  input {
    @include str-i;

    &:checked + div {
      .slide {
        background-color: $blue;
        &:before {
          translate: 20px 0px;
          background-color: white;
        }
      }
    }
  }
}

.checkbox {
  position: relative;
  display: inline-block;

  label {
    position: relative;
    margin-bottom: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $black;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      position: relative;
      display: inline-block;
      background: url(../images/icons/check-white.svg) no-repeat center;
      background-position: 15px 0px;
      background-color: #ffffff;
      border: 1px solid #a6b0c2;
      border-radius: 4px;
      margin-right: 10px;
      background-size: 14px;
    }
  }

  input {
    @include str-i;

    &:checked + label {
      color: $black;
      font-weight: 600;

      &:before {
        background-color: $blue;
        border-color: $blue;
        box-shadow: 0px 0px 0px 1px rgba($blue, 0.32);
        background-position: center;
      }
    }
  }
}

.MuiRadio-root {
  &.Mui-checked {
    svg {
      path {
        fill: $blue;
      }
    }
  }
}

.radio {
  position: relative;
  display: inline-block;
  padding: 15px 0 16px;

  label {
    position: relative;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    color: $dark;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      position: relative;
      display: inline-block;
      // background: url(../images/icons/check-white.svg) no-repeat;
      background-position: 20px;
      background-size: 12px;
      background-color: #ffffff;
      border: 1px solid #c8ccd7;
      border-radius: 50%;
      margin-right: 10px;
      top: 0px;
    }
  }

  input {
    @include str-i;

    &:checked + label {
      color: $dark;

      &:before {
        background-position: center;
        background-color: $blue;
        border: 1px solid $blue;
        box-shadow: 0px 0px 0px 1px rgba($blue, 0.12);
      }
    }
  }

  &.sm {
    label {
      font-size: 14px;
    }
  }
}

@mixin btn(
  $fs: 14px,
  $pd: 11px 24px,
  $fw: 700,
  $bg: $blue,
  $color: white,
  $border: $blue,
  $hc: white,
  $hbg: $blue,
  $dis: #dadada,
  $disabled: false
) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: $fw;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid $border;
  cursor: pointer;
  // box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
  transition: all $trans 180ms;
  // min-width: 150px;
  white-space: nowrap;
  $open: 'Open Sans', sans-serif;

  @include phone-m {
    // min-width: 170px;
    font-size: $fs;
    padding: $pd;
  }

  [class*="icon"] {
    margin-right: 6px;
  }

  &.sm {
    padding: 6px 12px;
    min-width: auto;
    font-size: 12px;
  }

  &.fs16 {
    line-height: 1.38;
  }

  &.small {
    padding: 6px 20px;
  }
  &.long {
    min-width: 250px;
    font-weight: 700;
    font-size: 16px;
  }

  &:hover,
  &.active {
    color: $hc;
    background-color: darken($color: $hbg, $amount: 10);
    box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
  }

  &:focus {
    animation: click 290ms ease forwards;
  }
  

  @if $disabled {
    background-color: $dis;
    border-color: $dis;
    color: #929191;
    box-shadow: none;

    &:hover {
      background-color: $dis;
      border-color: $dis;
      color: $text;
      box-shadow: none;
    }
  }

  @else {
    background-color: $bg;
    color: $color;
  }
}

@keyframes click {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
}

.btn {
  @include btn;
}

.btn.disabled,
.btn:disabled {
  @include btn($disabled: true );
}

.btn-blank {
  @include btn(
    $bg: transparent,
    $color: $text,
    $border: transparent,
    $hbg: $text,
    $hc: white
  );
  box-shadow: none;
}

.btn-white {
  @include btn(
    $bg: transparent,
    $color: $blue,
    $border: $blue,
    $hbg: $blue,
    $hc: white
  );
  box-shadow: none;

  &.disabled,
  &:disabled {
    @include btn($disabled: true);
  }
}

.btn-label {
  @include btn(
    $bg: rgba(77, 97, 170, 0.1),
    $color: $blue,
    $border: rgba(77, 97, 170, 0),
    $hbg: $blue,
    $hc: white
  );
  box-shadow: none;
  pointer-events: none;
}

$icons: (
  "user": 26px,
  "dashboard": 20px,
  "setting": 20px,
  "help": 20px,
  "logout": 20px,
  "search": 20px,
  "edit": 20px,
  "delete": 20px,
  "view": 20px,
  "mail": 20px,
  "password": 20px,
  "upload": 25px,
  "bookmark": 22px,
  "share": 22px,
  "right-caret": 8px,
  "back": 8px,
  "delete-white": 16px,
  "edit-blue": 28px,
  "lock": 20px,
  "block": 20px,
  "user-sm": 20px,
  "flag": 20px,
  "phone": 20px,
  "facebook": 20px,
  "twitter": 20px,
  "linkedin": 20px,
  "insta": 20px,
  "percentage": 20px,
  "text": 20px,
  "check-green": 14px,
  "cross-red": 14px,
  "plus": 10px,
  "minus": 10px,
  "dollar": 18px,
  "caret-right-black": 10px,
  "caret-left-black": 10px,
  "trash": 20px,
  "coupon": 24px,
  "caret-sm": 5px,
  "green-flag": 32px,
  "red-flag": 32px,
  "msg": 25px,
  "rupee": 20px,
  "copy": 18px,
);

@each $icon, $i in $icons {
  .#{$icon}-icon {
    width: $i;
    height: $i;
    display: inline-block;
    vertical-align: middle;
    background: url(../images/icons/#{$icon}-icon.svg) no-repeat center;
  }
}

.edit-blue-icon {
  background-size: contain;
}

.rupee-icon{
  background-size: contain;
  opacity: 0.8;
}

.user-icon.sm {
  background-size: contain;
  width: 18px;
}

// .twitter-icon{
//   background-size: 14px;
//   background-position: 4px 5px;
// }

// .facebook-icon{
//   background-size: 7px;
// }

// .linkedin-icon{
//   background-size: 14px;
//   background-position: 4px 3px;
// }

// .telegram-icon{
//   background-size: 16px;
//   background-position: 1px 5px;
// }
