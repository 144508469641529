@import "_media.scss";
@import "_variables.scss";
@import "_typo.scss";

// $black: black;

* {
  box-sizing: border-box;
}

body {
  color: $black;
  margin: 0;
  font-family: $open;
}

.row {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;

  > div {
    padding: 0 10px;
  }
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.text-xl-right{
  @include desk-m{
    text-align: end;
  }
}

$sm: 576;
$md: 768;
$lg: 992;
$xl: 1300;
$col-map: (
  "": 0,
  "-sm": $sm,
  "-md": $md,
  "-lg": $lg,
  "-xl": $xl,
);

@mixin rs($size, $width) {
  @if ($size == 0) {
    @content;
  } @else {
    @media screen and (#{$width}-width: #{$size}px) {
      @content;
    }
  }
}

$cols: 12;

@mixin cols-classes($device, $cols, $size) {
  @include rs($size, "min") {
    @for $i from 1 through $cols {
      .col#{$device}-#{$i} {
        flex: 0 0 (100 / ($cols / $i)) * 1%;
        max-width: (100 / ($cols / $i)) * 1%;
      }

      .col#{$device}-offset-#{$i} {
        margin-left: (100 / ($cols / $i)) * 1%;
      }
    }
  }
}

@each $device, $size in $col-map {
  @include cols-classes($device, $cols, $size);
}

@each $device, $size in $col-map {
  @include rs($size, min) {
    .d#{$device}-none {
      display: none !important;
    }

    .d#{$device}-block {
      display: block !important;
    }
  }
}

// @for  $i from 1 through $cols {
//     .col-#{$i}{
//         flex: 0 0 (100 / ($cols / $i)) * 1%;
//         max-width: (100 / ($cols / $i)) * 1%;
//     }
// }

.All-in {
  background-color: white;
  min-height: 100vh;
}

.wrapper {
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
}

.container {
  @include tab-m {
    max-width: 1200px;
  }
}

$wide: 210px;

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.main-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1050;
  background-color: $salmon;

  nav.navbar {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 24px 0 0;

    @include tabv-m {
      padding: 0 24px 0 0;
    }
  }

  a.navbar-brand {
    display: flex;
    align-items: center;
    color: $black;
    position: relative;
    background-color: $peach;
    padding: 18px 12px;
    width: $wide;
    text-align: center;
    flex-shrink: 0;

    img {
      max-width: 140px;
      margin: 0 auto;
    }
  }

  ul.navbar-nav {
    margin-left: auto;
    align-items: center;

    li {
      margin: 0 15px;

      @include tabv-m {
        margin: 0 20px;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
      }
    }
  }

  .hamBtn {
    margin-right: 10px;
    position: relative;
    display: inline-block;
    display: none;

    span {
      width: 18px;
      height: 1px;
      display: block;
      background-color: $black;
      margin: 4px 0;

      // &:nth-child(1){
      //   transform: rotate(45deg) translateY(1.6px) translateX(-2px);
      // }

      // &:nth-child(2){
      //   opacity: 0;
      // }

      // &:nth-child(3){
      //   transform: rotate(-45deg) translateY(-10px) translateX(7px);
      // }
    }
  }
}

.nav-menu {
  .MuiMenu-paper {
    padding: 8px 0px;
    background-color: $peach;

    ul {
      li {
        min-height: auto;
        font-size: 14px;
        color: $black;

        @include phone-m {
          font-size: 16px;
        }
      }
    }
  }
}

.dashboardSection {
  display: flex;
  // overflow: auto;
}

@mixin aside($width: 65px, $td: 250ms) {
  flex: 1 0 $width;
  max-width: $width;
  min-height: 100vh;
  background-color: $salmon;
  box-shadow: 1px 0px 0px #e7e7e7;
  transition: all ease 250ms;
  transition-delay: $td;
  // flex-shrink: 0;
}

aside {
  @include aside;
}

aside {
  &.active {
    @include aside($width: $wide, $td: 0ms);

    .sidebar {
      .sideText,
      .ouletSideText,
      .brandDropdown .dropdown-toggle:after {
        opacity: 1;
        // transition-delay: 200ms;
        visibility: visible;
      }
    }
  }
}

.sidebar {
  padding: 70px 0 40px;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  position: sticky;
  top: 0;
  // height: 100%;
  flex-wrap: nowrap;
  background-color: transparent;

  .menuList {
    li {
      margin-bottom: 4px;

      > a {
        display: flex;
        font-size: 12px;
        line-height: 1.4;
        font-weight: 400;
        align-items: center;
        position: relative;
        padding: 8px 6px 8px 16px;
        color: $black;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
          background-color: transparent;
          transition: all cubic-bezier(0.13, 0.4, 0.71, 0.99) 200ms;
        }

        &.active {
          font-weight: 600;
          background-color: $peach;

          &:before {
            background-color: $peach;
          }
        }

        &:hover {
          color: $text;
          &:before {
            background-color: $black;
          }
        }

        .menuIcons {
          min-width: 25px;
          margin-right: 7px;
          display: none;

          img {
            vertical-align: -4px;
          }
        }
      }
    }
  }
}

section.mainSection {
  flex: 1 1 100%;
  padding-top: 60px;
  overflow: auto;
  position: relative;
  background-color: #e5e5e5;

  @include phone {
    min-width: 600px;
  }
}

.main-in {
  padding: 14px;

  .main-div {
    position: relative;
    background-color: white;
    min-height: 80vh;
    box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
    border-radius: 4px;
    padding: 20px;

    &.h-auto {
      min-height: auto;
    }
  }
}

.user-manage {
  // overflow: hidden;
  margin-bottom: 100px;

  .react-multi-carousel-list {
    overflow: visible;

  }
  .btn-grp.flex-center.pt12{
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
  }
}

.blue-heading {
  display: flex;
  align-items: center;
  background-color: $blue;
  color: white;
  padding: 21px 20px;
  margin: -20px -20px 24px;

  .title {
    h6 {
      color: white;
      margin-bottom: 0;
    }
  }
}

.col-form {
  .form-in {
    margin-bottom: 16px;
  }
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .title {
    padding-right: 24px;
    text-transform: capitalize;
    margin-bottom: 0px;

    h6 {
      @include desk-m {
        font-size: 20px;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0px;
      font-weight: 600;
    }
  }

  &.bb {
    border-bottom: 0.5px solid rgba(80, 80, 80, 0.3);
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.flex-0{
  flex-grow: 0 !important;
}

.search-filter {
  position: relative;
  padding: 0 12px 8px;

  margin: 0 -20px 0px;
  display: flex;
  flex-wrap: wrap;

  .form-in{
    margin-bottom: 12px;
    @include desk-m{
      margin-bottom: 0;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border;
    margin-bottom: 24px;
  }

  .row{
    .flex-0{
      padding: 0 10px 16px;
    }
  }

  .cols {
    flex: 1 1 16%;
    padding: 0 10px 16px;
  }

  &.bb0 {
    border-bottom: none;
  }
}

.user-info {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &.no-grow{
    li{
      flex-grow: 0;
    }
  }

  li {
    flex: 1 0 33.33%;
    // max-width: 16%;
    padding: 0 15px 24px;
    margin-bottom: 0;

    small {
      color: $text;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 4px;
      display: block;
    }

    p {
      font-size: 14px;
      color: $blue;
      font-weight: 600;
      word-break: break-word;
    }
  }
}

.membership-div {
  .user-info {
    li {
      flex: 0 0 33%;
      margin-bottom: 0;
    }
  }

  &.reff-list{
    .user-info{
      margin: 0 -12px;
      li{
        flex: 0 0 25%;
        min-width: 120px;
        padding: 0 12px 16px;
      }
    }
  }
}

.center-btn,
.center-btn {
  .table-btns {
    justify-content: center;
  }
}

.table-btns {
  display: flex;
  align-items: center;

  button {
    &:not(.btn) {
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: all ease 160ms;
    }

    &.small {
      padding: 6px 14px;
      margin-left: 4px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.status-infom {
  > div {
    padding-top: 24px;
    border-top: 1px solid #d3d3d3;
  }
}

.status-info {
  display: flex;
  padding: 0 0 16px;
  // border-top: 1px solid #d3d3d3;

  > div {
    // background: #F9F9F9;
    // border-radius: 8px;
    // padding: 8px 12px 10px;
    padding: 20px 0 10px;
    width: 290px;
    margin-right: 16px;
    border-top: 1px solid #d3d3d3;

    .text {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      > div {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    h6 {
      font-size: 16px;
      // border-bottom: 1px solid #EFEFEF;
      padding-bottom: 6px;
      margin-bottom: 6px;
    }

    small {
      font-size: 12px;
      color: $text;
    }
    p {
      font-size: 14px;
      color: $blue;
      font-weight: 600;
    }
  }
}

.member-info {
  position: relative;
  background-color: $pink;
  padding: 16px 20px;
  // margin: 10px 0px 24px;
}

.react-multi-carousel-list {
  margin: 0 -20px;
  padding: 0 0px;

  .recp-info {
    margin: 0 -10px 20px -20px;
  }

  .react-multi-carousel-track  >li {
    padding: 0 20px;
  }

  // &:has(.membership-row){
  //   overflow-y: visible;
  // }
}

.membership-row {
  display: flex;
  margin: 0 -10px 24px;

  > div {
    width: 50%;
    padding: 0 10px;
  }

  &.gift-memb{
    margin: 0 -20px 24px;
    background: rgba(240, 242, 241, 0.4);
    padding: 0px 12px 18px;

    .membership-div{
      padding-top: 16px;
    }
  }
}

.recp-info {
  background: rgba(240, 242, 241, 0.4);
  padding: 15px 20px;
  margin: 0 -20px 20px;

  &.lower{
    .user-info{
      max-width: 600px;

      li{
        max-width: 33.33%;
        flex: 1 1 33.33%;
      }
    }
  }

  .btns-lower {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .user-info {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));

    li {
      // flex: 0 0 160px;
      flex: unset;
      padding-bottom: 10px;

      &:nth-child(1),
      &:nth-child(2) {
        word-break: normal;
      }

      &:first-child {
        min-width: 200px;
      }
    }
  }
}

// table
.full {
  margin-left: -20px;
  margin-right: -20px;
}

.table-sec {
  // width: 100%;
  overflow: auto;
  @include no-scrollbar;
}

.content-table {
  table {
    tr {
      td,
      th {
        &:not(:nth-child(1)) {
          text-align: center;
        }

        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}

.rev-table{
  tr{
    td, th{
      &:not(:first-child){
        text-align: center;
        width: 200px;

        td{
          text-align: center;
        }
      }

      &:first-child{
        width: 400px;
      }
    }
  }
}

.count {
  th {
    &:first-child {
      width: 76px;
    }
  }
}

table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 24px;

  th,
  .MuiTableCell-head {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: $black;
    padding: 18px 14px;
    background-color: #fafafb;
    border: none;
    text-align: left;
    width: auto;
    font-family: $font;
  }

  tr {
    &:nth-child(odd) {
      td,
      .MuiTableCell-bo {
        background-color: #ededed;
      }
    }
  }

  td,
  .MuiTableCell-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: $black;
    padding: 18px 14px;
    border: none;
    background-color: #f8f8f8;
    height: auto !important;
    min-height: auto;
    width: auto;
    font-family: $font;
  }
}

.yellow-line{
  tr{
    &:last-child{
      td{
        border-top: 1px solid $salmon;
      }
    }
  }
}

.white-table {
  table tr {
    th,
    td {
      background-color: white;
      padding: 4px;
    }
  }
}

.light-table {
  &.center-right{
    td,th{
      &:not(:first-child){
        text-align: center;
      }
    }
  }
  
  table tr {
    th {
      background-color: white;
    }
    td {
      background-color: white;
    }
    &:nth-child(odd) {
      td {
        background-color: #f8f8f8;
      }
    }
  }

  .total {
    td {
      border-bottom: 1px solid #999898;
      border-top: 1px solid #f1ddcc;
    }
  }
}

.grey-table {

  &.center{
    td,th{
      text-align: center;
    }
  }

  table tbody tr{
   
    th {
      background-color: #f8f8f8;
    }
    td {
      background-color: #f8f8f8;
    }
    &:nth-child(odd) {
      td {
        background-color: white;
      }
    }

  
  }

  thead tr{
    td {
      background-color: #f8f8f8;
    }
  }

  .total {
    td {
      border-bottom: 1px solid #999898;
      border-top: 1px solid #f1ddcc;
    }
  }
}

.lite-table {
  table tr {
    th {
      padding: 16px 14px;
      background-color: white;
    }
    td {
      padding: 9px 14px;
      background-color: white;
    }
    &:nth-child(odd) {
      td {
        background-color: #f8f8f8;
      }
    }
  }
}

.editable {
  &.min {
    min-width: 80px;
  }

  br {
    display: none;
  }

  .MuiInput-root {
    width: auto;
  }
  input {
    padding: 6px;
    color: $green;
    display: inline-block;
    min-width: auto;
    // border: none;
    border: 1px solid rgb(199, 199, 199) !important;
    width: 100%;
  }

  &.phone {
    display: flex;

    .MuiInputBase-input {
      border-left: none !important;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      margin: 0;
      height: 31px;
      box-sizing: border-box;
    }
    .MuiFormControl-root {
      height: auto;
    }

    .react-tel-input {
      width: auto;

      .flag-dropdown {
        height: 31px;
        background-color: transparent;
        border: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        width: 100%;

        li {
          font-size: 14px;
          margin-bottom: 4px;
        }

        .selected-flag {
          opacity: 0;
          width: 100%;
        }
      }

      .form-control {
        line-height: 1;
        height: 31px;
        // border-right: none !important;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding-left: 10px;
        max-width: 76px;
        background: url(../images/icons/caret-down-icon.svg) no-repeat;
        background-position: calc(100% - 7px) 13px;
        background-size: 10px;
      }
    }
  }
}

.w-100{
  width: 100%;
}

.btns-lower {
  display: flex;
  justify-content: center;
  padding: 24px 12px;
  margin-left: -10px;
  margin-right: -10px;

  &.d-flexx{
    flex-direction: row;
  }

  .btn-grp{
    text-align: right;
    justify-content: flex-end;
  }

  > a,
  > button,
  > div {
    margin: 0 8px;
  }
}

.customer-edit {
  .form-in {
    margin-bottom: 24px;
  }
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $salmon;

  .wrapper {
    max-width: 512px;
    margin: 0 auto;
    flex: 1 0;
  }

  .form-in {
    margin-bottom: 24px;

    label {
      color: $black;
      font-size: 14px;
    }
  }

  .text {
    background: #ffffff;
    position: relative;
    box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
    border-radius: 20px;

    .head {
      text-align: center;
      padding: 48px 24px 0;

      .logo {
        max-width: 152px;
        margin: 0 auto 10px;
      }
    }

    .body {
      padding: 24px 44px;

      .title {
        text-align: center;
        font-weight: 400;
      }
    }

    .foot {
      position: relative;
      text-align: center;
      padding: 0 44px 46px;

      .btn {
        &.single {
          font-size: 15px;
          padding: 14px 24px;
          min-width: 152px;
        }
      }
    }
  }
}

.link {
  color: $blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.MuiPagination-root {
  border-top: 1px solid #eff0f4;
  padding-top: 16px;

  .MuiPagination-ul {
    justify-content: flex-end;

    li {
      button {
        border: 1px solid #eff0f4;
        &.Mui-selected {
          background-color: $blue;
          color: white;
          border-color: $blue;
        }
      }
    }
  }
}

.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #796f67;

  .icon {
    width: 28px;
    height: 28px;
    background: rgba(0, 0, 0, 0.5);
    border: 0.5px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-right: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.main-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.20);
  // backdrop-filter: blur(5px) ;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  // .logo-pulse{
  //   width: 70px;
  //   height: 70px;
  //   background: url(../../../assets/images/logo-dark.svg) no-repeat center;
  //   animation: pulse1 1.1s cubic-bezier(0.08, 0.02, 0.96, 0.99) 0ms infinite reverse;
  //   background-size: contain;
  // }

  // .logo-pulse{
  //   display: flex;

  //   span{
  //     margin: 0 3px;
  //     width: 10px;
  //     height: 10px;
  //     border-radius: 50%;
  //     background-color: white;
  //     animation: wave 1s cubic-bezier(0.21, 0.17, 0.63, 0.93) -100ms infinite reverse;

  //     &:nth-child(2){
  //       animation-delay: 150ms;
  //     }

  //     &:nth-child(3){
  //       animation-delay: 300ms;
  //     }

  //     &:nth-child(4){
  //       animation-delay: 450ms;
  //     }
  //   }
  // }

  .logo-pulse {
    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 4px solid white;
      border-top-color: transparent;
      display: inline-block;
      animation: rotate 400ms linear infinite forwards;

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes wave {
  0%,
  100% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, -14px);
  }
}

@keyframes pulse1 {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.85);
    opacity: 0.7;
  }
}

.MuiDialog-paper {
  background-color: transparent !important;
  overflow: visible !important;
  box-shadow: none !important;
}

.MuiTypography-root {
  font-family: $open !important;
}

.social-share{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 380px;
  margin: 0 auto;

  div{
    position: relative;
    padding: 0 4px 16px;
    width: 25%;

    @include phone-m{
      padding: 0 8px 16px;
      padding-bottom: 24px;
      width: 23%;
    }
    p{
      font-weight: 400;
      font-size: 12px;
      margin-top: 8px;
      white-space: nowrap;
    }
  }
}


.share-modal{
  max-width: 526px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .close-modal{
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0;
  }
  
  .modal-content{
    background-color: $peach;
    border-radius: 16px;
  }

  .head{
    padding: 24px 24px 0;
    h5{
      margin-bottom: 0px;
    }
  }
  hr{
    opacity: 0.4;
  }
  .body{
    padding-top: 18px;
    padding-bottom: 8px;
  }

  .modal-content{
    max-width: 100%;
  }
}

.compli-modal{
  max-width: 712px;

  .MuiDialogContent-root{
    padding: 10px 10px 0 10px;
    overflow: hidden;
  }
}

.link-tip{
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0px);
  white-space: nowrap;
  background-color: $green;
  color: white;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  box-shadow: 1px 2px 10px 0 rgba(14, 97, 6, 0.28);
  opacity: 0;
  visibility: hidden;
  transition: all $trans 200ms;
  // text-transform: uppercase;

  @include phone-m{
    bottom: 26px;
  }

  &.active{
    opacity: 1;
    visibility: visible;
    bottom: 12px;

    @include phone-m{
      bottom: 20px;
    }
  }
}

.sharing-icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid $black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all $trans 180ms;
  cursor: pointer;

  @include phone-m{
    width: 36px;
    height: 36px;
  }

  i{
    width: 12px;
    height: 12px;
    background-size: contain;
    background-position: center;

    @include phone-m{
      width: 16px;
      height: 16px;
    }

    &.telegram-icon{
      background: url(../images/icons/telegram-icon.svg) no-repeat center;
    }

    &.fb-icon{
      background: url(../images/icons/fb-icon.svg) no-repeat center;
    }

    &.twitter-icon{
      background: url(../images/icons/twitter1-icon.svg) no-repeat center;
    }

    &.linkedin-icon{
      background: url(../images/icons/linked-icon.svg) no-repeat center;
    }

    &.whatsapp-icon{
      background: url(../images/icons/whatsapp-icon.svg) no-repeat center;
    }

    &.msgr-icon{
      background: url(../images/icons/msgr-icon.svg) no-repeat center;
    }

    &.email-icon{
      background: url(../images/icons/email-icon.svg) no-repeat center;
    }

    &.link-icon{
      background: url(../images/icons/link-icon.svg) no-repeat center;
      background-size: contain;
    }
  }
  

  &:hover{
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.05)
  }
}

.MuiDialog-container{
  overflow: auto;
}

.modal-text {
  position: relative;
  padding: 24px;
  background-color: white;
  box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
  border-radius: 14px;
  min-width: 460px;

  &.free-modal{
    min-width: auto;
    width: 100%;
    max-width: 560px;
  }

  .MuiDialogContentText-root {
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;

    button {
      background: $black url(../images/icons/cross-peach-icon.svg) no-repeat
        center;
      border-radius: 8px;
      color: $salmon;
      font-size: 25px;
      line-height: 1;
      padding: 4px 10px;
      cursor: pointer;
      border: 1px solid $salmon;
      width: 32px;
      height: 32px;
      box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
      span {
        opacity: 0;
      }
    }
  }

  .foot {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    > a,
    button,
    div {
      font-weight: 700;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

// Topic Management

.topic-chips {
  label {
    font-size: 12px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }

  .chip-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;

    .MuiSvgIcon-root {
      box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
      border-radius: 50%;
      padding: 0;
      background: white url(../images/icons/cross-sm-icon.svg) no-repeat center;
      width: 18px;
      height: 18px;

      path {
        fill: white;
      }
    }
  }

  .MuiChip-root {
    margin-right: 10px !important;
  }

  li {
    list-style: none;
  }
}

.chip-label {
  position: relative;
  display: inline-block;
  padding: 7px 20px;
  font-weight: 400;
  font-size: 14px;
  background: rgba(43, 46, 54, 0.1);
  border-radius: 38px;
  color: $black;

  &.blue {
    background: rgba(77, 97, 170, 0.1);
    color: $blue;
  }
}

.status-label {
  position: relative;
  display: inline-block;
  padding: 9px 12px;
  font-weight: 600;
  font-size: 12px;
  background: rgba(77, 97, 170, 0.1);
  border-radius: 9px;
  color: $blue;
  min-width: 78px;
  text-align: center;
  white-space: nowrap;

  &.red {
    background: rgba(255, 59, 59, 0.14);
    color: $red;
  }

  &.fill-grey {
    background: #d3d3d3;
    color: $text;
  }

  &.fill-green {
    background: #5cc928;
    color: white;
  }

  &.upper {
    text-transform: uppercase;
  }
}

.topic-add {
  .f-in {
    display: flex;

    input {
      margin-right: 6px;
    }

    .btn {
      font-size: 30px;
      padding: 4px 14.6px;
      font-weight: 500;
    }
  }
}

.MuiChip-root.MuiChip-filled {
  background: rgba(77, 97, 170, 0.05);
  border-radius: 40px;

  .MuiChip-label {
    color: $blue;
  }
}

.topic-row {
  position: relative;
  display: flex;
  // align-items: flex-start;
  margin: 0 -20px;

  &.header {
    background-color: #fafafb;
    // padding: 4px 0;
    .topic-title {
      border-right: none;

      h5 {
        margin-bottom: 0px;
        font-size: 15px;
        font-weight: 600;
        padding: 0;
        background-color: transparent;
      }
    }
    .th-title {
      width: 100%;
      padding-right: 30px;
    }

    table {
      margin-bottom: 0px;
      th {
        font-size: 15px;
        background-color: transparent;
      }

      thead {
        tr {
          th {
            border-top: 0.5px solid #e1e1e1;
            &:nth-child(1) {
              width: 33%;
            }
            &:nth-child(2) {
              width: 33%;
              text-align: center;
            }
            &:nth-child(3) {
              width: 30%;
              text-align: right;
            }
          }
        }
      }
    }
  }

  &:last-child {
    .topic-left,
    .topic-body {
      border-bottom: 0.5px solid #e1e1e1;
    }
  }
  .topic-left {
    border: 0.5px solid #e1e1e1;
    border-bottom: none;
    width: 300px;
    flex-shrink: 0;

    h5 {
      padding: 16px;
      margin-bottom: 0px;
      font-size: 15px;
      font-weight: 600;
      background-color: whitesmoke;
    }
  }

  .topic-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .editable {
      padding-right: 6px;
      .MuiInputBase-root {
        width: auto;
      }
    }

    .MuiSwitch-root{
      margin: 0 6px 0 auto;
    }

    .MuiButtonBase-root {
      padding: 2px;
    }

    h6 {
      font-size: 16px;
      color: $blue;
    }

    .btn {
      padding: 6px 12px;
      font-size: 13px;
    }
  }

  .topic-body {
    display: flex;
    align-items: center;
    padding: 16px;
    flex: 1 1;
    border-top: 0.5px solid #e1e1e1;
    // border-right: 0.5px solid #E1E1E1;

    .MuiPaper-root {
      margin: -16px;

      .MuiTableRow-root {
        &:not(:last-child) {
          td {
            border-bottom: 1px solid #e1e1e1;
          }
        }
      }

      tr {
        th {
          font-size: 15px;
          background-color: whitesmoke;
          padding: 16px;
        }

        td {
          padding: 16px;
        }
      }
    }

    table {
      tbody {
        .MuiTableRow-root {
          .MuiTableCell-body {
            &:nth-child(1) {
              width: 33%;
            }
            &:nth-child(2) {
              width: 33%;
              text-align: center;
            }
            &:nth-child(3) {
              width: 30%;
              text-align: right;
            }
          }
        }
      }
    }
  }

  .MuiPaper-root {
    width: 100%;
    box-shadow: none;
    margin-top: 0px;
    text-align: center;
    overflow: visible;
  }
}

.MuiPaper-root {
  &:has(.modal-text) {
    max-width: 100%;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
  }
}

.btn-plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: rgba($blue, 0.1);
  border-radius: 4px;
  color: $blue;
  border: none;
  font-size: 24px;
  align-self: flex-start;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 30px;
}

.no-content {
  padding: 50px 24px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  h6 {
    margin-bottom: 8px;
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}

.content-type {
  margin-bottom: 30px;

  &.form-in {
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiFormGroup-root {
      width: 100%;
      justify-content: space-between;

      label {
        width: 48%;
        margin-right: 0 !important;
      }
    }
  }

  p {
    margin-bottom: 12px;
  }
  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;

    > label {
      padding: 10px 20px 10px 12px;
      border: 1px solid rgb(223, 223, 223);
      border-radius: 4px;
      margin: 0;

      &:has(.Mui-checked) {
        border-color: $blue;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      .MuiTypography-root {
        font-size: 14px;
        font-weight: 400;
        margin-left: 10px;
        font-family: $font;
      }

      .MuiRadio-root {
        padding: 0;
      }
    }
  }
}

.dashboard-cards{
  display: flex;
  padding: 24px 0;
  margin: 0 -10px;

  >div{
    flex: 1 1 20%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 10px;
    margin: 0 10px;
    text-align: center;

    h5{
      margin-bottom: 0;
      font-size: 26px;
      font-weight: 700;
    }

    p{
      margin-bottom: 16px;
      font-weight: 700;
    }
  }
}

.effective-div{
  display: flex;
  margin: 0 -20px;
  >div{
    flex: 1 1 50%;
    padding: 0 20px;
  }

  h5{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}

.table-reports{
  >div{
    padding-top: 24px;
    display: flex;
    flex-direction: column;

    >div{
      flex: 1 1;
    }
  }
  
}

.report-analytics{
  .heading, table{
    &:not(.no-sm){
      .btn, .btn-white{
        font-size: 12px;
        padding: 9px 10px;
        line-height: 1;
      }
    }
  }

  table{
    .btn, .btn-white{
      min-width: 100px;
    }
  }

  >.col-md-6{
    display: flex;

    .main-div{
      flex: 1 1 100%;
    }
  }

  .date-pick.date-input{
    max-width: 310px;
  }
}

.btn-msg{
  padding: 8.5px;
  background-color: $blue;
  border: none;
  border-radius: 4px;
}

.content-flex{
  h6{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d3d3d3;
    padding-top: 24px;
  }
  p{
    display: flex;
    justify-content: space-between;
    color: #676767;
    font-size: 14px;

    b{
      font-size: 14px;
    }
   
    &:not(:last-child){
      padding-bottom: 10px;
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 10px;
    }
  }
}

.select-option{
  text-align: right;
  padding-bottom: 10px;
  margin: 0 -7px;

  >div{
    display: inline-block;
    text-align: left;
    font-size: 14px;
    padding: 0 7px 14px;

    width: auto;
  }
  
  [class*="menu"]{
    font-size: 12px;
    min-width: 100px;
  }

  &.lg{
    [class*="menu"]{
      font-size: 12px;
      min-width: 160px;
    }
  }
}

.eff-inner{
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  padding-top: 10px;

  &:not(:last-child){
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 10px;
  }

  >div{
    display: flex;
    align-items: center;
    .recharts-wrapper{
      margin-right: 12px;
    }
  }

  p{
    &:not(.num){
      line-height: 1.2;
      font-weight: 400;
      font-size: 14px;
      color: $text;
    }

    &.num{
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.publish-date {
  max-width: 500px;
  margin-bottom: 30px;

  .form-in {
    position: relative;
    z-index: 10;
  }
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 4px solid white;
    border-top-color: transparent;
    display: inline-block;
    animation: rotate 400ms linear infinite forwards;
  }
}

.loader-svg {
  transform: scale(1.5);
}

.logo-pulse .svg-elem-1 {
  stroke-dashoffset: 99.40499114990234px;
  stroke-dasharray: 99.40499114990234px;
  stroke: white;
  //  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
  //                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  animation: load1 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite forwards;
}

.logo-pulse .svg-elem-2 {
  stroke-dashoffset: 122.0291976928711px;
  stroke-dasharray: 122.0291976928711px;
  fill: transparent;
  //  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
  //                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
  animation: loadd 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite forwards;
}

@keyframes load1 {
  0% {
    stroke-dashoffset: 99.40499114990234px;
  }

  20% {
    stroke-dashoffset: 99.40499114990234px;
  }
  80% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@keyframes loadd {
  0% {
    stroke-dashoffset: 122.0291976928711px;
    fill: transparent;
  }
  70% {
    stroke-dashoffset: 0;
    fill: white;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

.img-upload {
  position: relative;
  background: #f8f8f8;
  border: 1px dashed #d3d3d3;
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  // padding-bottom: 20%;
  aspect-ratio: 4/1.202;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &.uploaded {
    .text {
      opacity: 0;
      visibility: hidden;
    }
  }

  input {
    @include str-i;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    width: 100%;

    .accept-img {
      font-size: 12px;
      color: $text;
      padding-top: 4px;
    }

    .icon {
      margin-bottom: 12px;
    }
  }

  img {
    @include img;
    border-radius: 6px;
  }
}

.delete-img {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;

  button {
    color: transparent;
    background: #696969 url(../images/icons/delete-white-icon.svg) no-repeat
      center;
    border: 0.5px solid #ffffff;
    border-radius: 6px;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
}

.delete-btn {
  position: relative;
  z-index: 10;

  button {
    color: transparent;
    background: #696969 url(../images/icons/delete-white-icon.svg) no-repeat
      center;
    border: 0.5px solid #ffffff;
    border-radius: 6px;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
}

.multi-select {
  [class*="control"] {
    [class*="multiValue"] {
      background: rgba(77, 97, 170, 0.05);
      border-radius: 40px;
      padding: 8px 4px 8px 10px;
      align-items: center;

      > div {
        color: $blue;
        padding: 0px;

        &:nth-child(2) {
          border-radius: 50%;
          background-color: white;
          width: 18px;
          height: 18px;
          box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 6px;
          color: $black;
          cursor: pointer;

          &:hover {
            color: $black;
          }
        }
      }
    }
  }
}

.mobile-banner {
  margin-top: 130px;
  @include desk-m {
    margin-top: -94px;
  }

  .img-upload {
    // height: 424px;
    max-width: 250px;
    aspect-ratio: 9/15.95;
  }
}

.icon-upload {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.15);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.content-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 -20px 32px;

  @include desk-m {
    flex-direction: row;
  }

  > div {
    padding: 0 20px;
    min-width: 400px;
  }

  .cols {
    max-width: 720px;
    flex: 1 0;

    @include desk-m {
      max-width: 60%;
    }
  }
}

.content-piece {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  figure {
    width: 346px;
    height: 534px;
  }

  .form-in {
    margin-bottom: 24px;
  }
}

.content-img {
  width: 346px;
  .img-upload {
    aspect-ratio: 4/5;
  }
}

.main-div.content-btns {
  min-height: auto;
  padding: 18px 24px;

  .btns-lower {
    padding: 0;
  }
}

.in-div{
  position: relative;
  padding: 20px 20px;
  background: #FFFFFF;
  border: 1px solid rgba(96, 93, 93, 0.4);
  border-radius: 8px;

  &:not(:last-child){
    margin-bottom: 24px;
  }
}

.fb-1 {
  flex: 1 1;
}

.web-preview {
  border: 2px solid #d3d3d3;
  padding: 12px;
}

.preview-div {
  position: relative;
  margin-top: 24px;

  &.mobile {
    .mobile-div {
      max-width: 401px;
      margin: 0 auto;
      border: 2px solid #d3d3d3;
      padding: 12px;
    }
    .cards-row {
      margin: 0 !important;

      > div {
        width: 100%;
        padding: 0 0 24px;

        @include phone-m {
          flex: 1 1 100%;
          max-width: 100%;
        }

        .article-card {
          max-width: 100%;
          border-radius: 0;
        }
      }
    }

    .article-card {
      // min-height: calc(100vh - 110px);
      border: none;
      box-shadow: none;
      // border-bottom: 1px solid #D5D5D5;
      margin-bottom: 4px;
      // @include height{
      min-height: auto;
      // }

      figure {
        position: relative;
        height: auto;
        padding-bottom: 122%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          object-fit: contain;
        }
      }

      footer {
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 8px;

        @include phone {
          padding-bottom: 20px;
        }

        .blank-btn {
          background-color: transparent;
          font-size: 14px;
          width: auto;
          padding-right: 16px;
          text-transform: uppercase;

          &:after {
            display: none;
          }

          &.listen {
            background-color: $blue;
            color: white;
            border-radius: 4px;
            font-size: 12px;
            padding: 5px 12px 5px 15px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .right-caret-icon {
              margin-left: 4px;
              filter: none;
              transform: scale(1.1);
            }
          }
        }
      }

      .text {
        min-height: auto;
        background-color: white;
        box-shadow: 0px -6px 8px rgba(0, 0, 0, 0.1);

        small {
          font-size: 10px;
        }

        @media (min-width: 400px) and (min-height: 700px) and (max-height: 750px) {
          padding-bottom: 12px;

          p {
            margin-bottom: 2px;
          }
        }

        // @media (max-width: 450px) and (max-height: 700px) {
        //   padding-bottom: 12px;
        // }

        // @media (min-width: 400px) and (min-height: 750px) and (max-width: 500px) {
        //   padding-bottom: 12px;
        // }

        p {
          // min-height: auto;
          min-height: 100px;

          @media (min-width: 400px) and (min-height: 800px) and (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }

    .preview-banner {
      figure {
        width: 100%;
        aspect-ratio: 9/16;
      }
    }
  }
}

.preview-banner {
  figure {
    position: relative;
    width: 100%;
    padding-bottom: 30%;
    background-color: whitesmoke;

    img {
      @include img;
    }
  }
}

.cards-row {
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto;
  max-width: 1230px;

  @include phone-m {
    justify-content: center;
  }

  @include tabv-m {
    margin: 30px -10px;
    justify-content: center;
  }

  @include tab-m {
    margin: 30px auto;
  }

  > div {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0 10px 30px;
    display: flex;

    @include phonev-m {
      flex: 0 1 50%;
    }

    @include phone-m {
      flex: 0 1 45%;
    }

    @include tabv-m {
      flex: 1 1 33.333%;
      max-width: 33.333%;
      padding: 0 10px 60px;
    }

    @include tab-m {
      flex: 1 1 33.333%;
      max-width: 410px;
      padding: 0 10px 60px;
    }

    @include desk-m {
      padding: 0 30px 60px;
    }
  }
}

.article-card {
  position: relative;
  overflow: hidden;
  box-shadow: 4px 5px 10px rgba(19, 19, 19, 0.08), inset 0px 0px 0px 1px #dfcbba;
  border: 1px solid #d3d3d3;
  transition: all $trans 300ms;
  display: flex;
  flex-direction: column;
  flex: 1 1;

  @include phonev-m {
    border-radius: 8px;
    max-width: 350px;
  }

  > a {
    @include str;
    display: none;
  }

  header {
    background-color: $salmon;
    padding: 14px 10px;
    text-align: center;

    p {
      font-weight: 400;
      color: $black;
      font-family: $old;
      line-height: 1;
      text-transform: capitalize;
      font-size: 22px;
    }
  }

  figure {
    position: relative;
    background-color: rgb(197, 197, 197);
    // padding-bottom: 125%;
    // z-index: 1;
    aspect-ratio: 4/5;

    span {
      position: absolute !important;
      top: 0px;
      bottom: 0;
      left: 0;
      right: 0;
    }

    img {
      position: absolute;
      top: 0px;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .text {
    position: relative;
    z-index: 2;
    padding: 20px 16px 24px;
    background-color: white;
    border-radius: 30px 30px 0 0;
    margin-top: -28px;
    // min-height: 180px;
    flex: 1 1;

    @include phonev-m {
      background-color: $pink;
    }

    p {
      font-size: 14px;
      color: $black;
      font-weight: 400;
      opacity: 0.7;
      line-height: 1.38;
      min-height: 100px;

      @include phone-m {
        // font-size: 12px;
        min-height: 118px;
      }

      @include tab-m {
        font-size: 14px;
      }
    }

    .labels {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      @media (max-width: 380px) and (max-height: 700px) {
        margin-bottom: 12px;
      }

      mark {
        padding: 8px 12px;
        border-radius: 4px;
        background-color: $peach;
         font-size: 12px;
        font-weight: 700;

        // @include gal{
        //   font-size: 8px;
        // }

        @include phone-m {
          font-size: 12px;
        }

        // @media (max-width:380px) and (max-height: 700px){
        //   font-size: 12px;
        // }

        // @media (min-width:400px) and (min-height: 700px) and (max-height: 780px){
        //   font-size: 12px;
        // }
      }

      small {
        font-size: 10px;
        font-style: italic;
        color: $text;
        text-transform: uppercase;
      }
    }
  }

  footer {
    position: relative;
    display: flex;
    z-index: 5;
    margin-top: auto;

    button {
      position: relative;
      width: 50%;
      text-align: center;
      background-color: #e6e6e6;
      padding: 9px 8px;
      transition: all $trans 200ms;

      @include phone-m {
        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            display: inline-block;
            right: -0.5px;
            top: 4px;
            bottom: 4px;
            border-right: 1px solid #d2d2d2;
            z-index: 2;
          }
        }
      }

      i {
        filter: grayscale(1) brightness(0.8);
        transition: all $trans 200ms;
      }

      &:hover {
        background-color: #f2f2f2;
        i {
          filter: grayscale(0) brightness(1);
        }
      }
    }
  }
}

.category-table {
  tr {
    th,
    td {
      &:nth-child(3),
      &:last-child {
        text-align: center;
      }
    }
  }
}

.mr-10 {
  padding-left: 10px;
}

.z10 {
  z-index: 20;
}

.date-pick {
  display: flex;
  align-items: center;

  .sep {
    margin: 0 10px;
  }
}

.sub-admin-search {
  flex: 1 1;
  max-width: 395px;

  input {
    border-color: #d3d3d3;
  }
}

.rate-div{
  &:not(:last-child){
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 20px;
  }
}

.rate-title {
  font-weight: 600;
  font-size: 16px;
  span {
    display: inline-flex;
    align-items: center;
    background: #f7f7f7;
    border-radius: 6px;
    padding: 8px 16px;

    b {
      margin-left: 4px;
      margin-right: 4px;
      font-weight: inherit;
      // font-size: 12px;
      // color: $black;
    }
  }
}

.rate-box {
  padding: 20px 0 10px;
  position: relative;
  max-width: 220px;

  .MuiSwitch-root {
    box-shadow: none;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #d3d3d3;
    padding-bottom: 10px;
    margin-bottom: 16px;

    h6 {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }

  .user-info {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin: 0 0;
    column-gap: 20px;
    row-gap: 10px;

    li {
      flex: unset;
      padding: 0 0;
    }
  }
}

.Toastify__toast-container.toast-boxes {
  padding: 0;
  width: auto;
  .Toastify__toast {
    background-color: transparent;
    box-shadow: none;
    padding: 0;

    .Toastify__toast-icon {
      display: none;
    }
  }

  .Toastify__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.tn-box {
  position: relative;
  margin-bottom: 16px;
  background: #fff0e3;
  // border-bottom: 4px solid #5CC928;
  box-shadow: 4px 4px 12px rgba(6, 6, 6, 0.14);
  border-radius: 15px;
  padding: 18px;
  width: 312px;
  display: flex;
  align-items: flex-start;
  transition: all $trans 170ms;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0;
    height: 30px;
    border-bottom: 6px solid transparent;
    border-bottom-color: #5cc928;
    border-radius: 60px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 16px;
    z-index: 1;
    background-color: $peach;
    animation: progress-line 5s linear forwards;
  }

  &:hover {
    &:before {
      animation-play-state: paused;
    }
  }

  @keyframes progress-line {
    0% {
      right: 100%;
    }
    100% {
      right: 0;
    }
  }

  &.active {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }

  &.red {
    border-color: #ff484d;

    // .check-green-icon{
    //   background: url(../images/icons/cross-red-icon.svg) no-repeat center;
    // }

    &:after {
      border-bottom-color: #ff484d;
    }
  }

  .text {
    padding-left: 12px;
  }

  h6 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.52;
  }

  figure {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #2b2e36;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.emailer-div {
  position: relative;
  display: flex;
  margin-bottom: 32px;

  &:not(:last-child) {
    border-bottom: 1px solid rgb(192, 192, 192);
    padding-bottom: 32px;
  }

  .img-upload {
    width: 200px;
    height: 250px;
    aspect-ratio: auto;
    flex-shrink: 0;
    text-align: center;
  }

  > .text {
    padding-left: 20px;
    flex: 1 1;
  }
}

.email-preview .emailer-table {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  background-color: #f8f4fa;
  border: none;
  border-spacing: 0;

  &.mobile {
    max-width: 400px;

    .emailer-cards {
      text-align: center;

      > div {
        padding-bottom: 6px;
      }
    }
  }

  table {
    margin-bottom: 0;
  }

  tr {
    td {
      background-color: transparent;
      padding: 0;
    }
  }

  .top-td {
    td {
      padding: 20px 15px;

      p {
        font-size: 12px;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .footer-td {
    padding: 22px 10px;
    position: relative;
    text-align: center;
    background-color: #f0f2f1;

    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $text;
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      max-width: 351px;
      margin: 0 auto 16px;
      color: $text;

      a {
        color: $blue;
        text-decoration: underline;
        font-weight: 500;
      }
    }

    > a {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $text;
      text-decoration: none;
    }
  }

  .logo-td {
    text-align: center;
    padding-bottom: 18px;
  }

  .emailer-btn {
    margin-top: 32px;
    text-align: center;
    button {
      padding: 10px 24px;
      background-color: $salmon;
      box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
      border-radius: 4px;
      border: none;
      font-weight: 600;
      font-size: 10px;
    }
  }
}

.email-cards {
  max-width: 454px;
  margin: 0 auto 30px;
  text-align: center;
  hr {
    margin: 6px 10px 10px;
    background-color: #d3d3d3;
    opacity: 0.3;
  }
}

.emailer-cards {
  display: inline-block;
  padding: 0 0px 26px;

  > div {
    width: 150px;
    display: inline-block;
    padding: 20px 10px 0px;
    text-align: center;
  }

  img {
    border-radius: 8px;
    width: 100%;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: rgb(97, 98, 102);
    margin: 4px 0 6px;
    font-style: italic;
  }

  small {
    font-family: "Open Sans", sans-serif;
    margin: 0 0 4px;
    font-weight: 700;
    font-size: 10px;
    color: #2b2e36;
    text-transform: uppercase;
    display: block;
  }

  a {
    background: #4d61aa;
    box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);
    border-radius: 2px;
    color: white;
    font-weight: 700;
    font-size: 7px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;

    i {
      margin-left: 2px;
      margin-top: -1px;
    }
  }
}

.compli-search {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -10px;

    p {
      font-size: 16px;
      font-weight: 600;
    }

    .btn {
      padding: 10px 10px 20px;
      margin-right: 10px;
      i {
        filter: invert(1) brightness(2);
      }
    }
  }
}

.compli-listing {
  position: relative;
  z-index: 2;
  display: flex;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05),
    0px 12px 24px rgba(0, 72, 217, 0.05);
  border-radius: 12px;
  padding: 24px 10px 4px;

  ul {
    position: relative;
    width: 33.333%;
    padding: 0 20px;

    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        bottom: 10px;
        right: 20%;
        border-right: 1px solid rgb(216, 216, 216);

      
      }
    }

    li {
      margin-bottom: 16px;
      max-width: 70%;

      &.flex {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;

        .form-in {
          width: 150px;
        }

        > div {
          &:last-child {
            margin-left: 32px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
        color: $black;
        font-weight: 600;
        margin-bottom: 4px;
      }

      small {
        font-size: 16px;
        font-weight: 400;
        color: $text;
      }

      .form-in{
        label{
          font-size: 16px;
          color: #2b2e36;
          font-weight: 600;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.comp-table {
  tfoot {
    td {
      background-color: transparent;
      border: none;
    }
  }

  &.center-right{
    table{

      td,th{
        &:not(:first-child){
          text-align: center;
        }
      }
    }
  }
}

.comp-search {
  display: flex;
  position: relative;
  padding: 0 24px 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid #d3d3d3;

  .form-in {
    padding-right: 12px;
    flex: 1 1 100%;
    max-width: 600px;
  }
}

.reff-in {
  position: relative;
  max-width: 1028px;
  margin: 0 auto;
  background-color: $peach;
  padding: 32px 30px;

  .d-flexx {
    display: flex;
    align-items: center;
  }
}

.slider-arrows {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    padding: 0 12px;
  }

  button {
    background-color: transparent;
    border: 1.5px solid #373737;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.refferal-modal {
  min-width: 712px;
  max-width: 712px;

  .MuiDialogContent-root {
    padding: 10px 6px 0;
    overflow: hidden;
  }

  .heading {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 14px;
    margin-bottom: 22px;
  }

  .user-info {
    li {
      p {
        color: $black;
      }
    }
  }
}

.refund-modal {
  min-width: 712px;

  .MuiDialogContent-root {
    padding: 6px 6px;
    overflow: hidden;
  }

  .heading {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 14px;
    margin-bottom: 22px;
  }

  .user-info {
    &.fifty {
      li {
        flex: 0 0 50%;
      }
    }
    li {
      p {
        color: $black;
      }
    }
  }
}

.email-modal {
  min-width: 563px;

  .heading {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 18px;
    margin-bottom: 28px;
    justify-content: center;

    h6 {
      text-align: center;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .DraftEditor-editorContainer .public-DraftEditor-content{
    min-height: 140px;
    max-width: 600px;
    max-height: 250px;
    overflow: auto;
  }
}


.cancel-details {
  background: #ffffff;
  border: 1px solid #696969;
  border-radius: 8px;
  padding: 24px;
  margin-top: 32px;

  .btns-lower{
    justify-content: space-between;
  }
}

.main-div{
  &:has(.refund-div), &:has(.referral-form){
    margin-bottom: 70px;
  }
}

.referral-form, .refund-div {
  .slider-arrows{
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    right: 0;
  }
}

.refund-div {
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
}

.refund-sec {
  position: relative;
  background-color: $pink;
  padding: 32px;
  margin-bottom: 32px;

  .bb {
    &:not(:last-child) {
      border-bottom: 1px solid rgb(208, 208, 208);
      padding-bottom: 28px;
      margin-bottom: 28px;
    }
  }

  .f-in label.MuiFormLabel-filled {
    background-color: $pink !important;
  }
}

.error-page {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 68px;
    margin-bottom: 6px;
  }

  h4 {
    margin-bottom: 10px;
  }

  .icon {
    max-width: 100px;
    margin: 0 auto;
  }

  > div {
    background-color: whitesmoke;
    padding: 46px 68px;
    border-radius: 8px;
    margin-bottom: 36px;
  }
}

.slider-over {
  overflow-x: hidden;

  .react-multi-carousel-list {
    overflow: visible;
  }
}

.date-filter {
  display: flex;
}

.count-cards{
  display: flex;
  margin: 0 -10px;

  >div{
    width: 33%;
    padding: 10px;
  }

  .c-card{
    background: #F9F4FA;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    min-height: 200px;

    p{
      font-size: 14px;
      margin-bottom: 8px;
    }

    h6{
      font-size: 20px;
    }
  }
}

.pie-div{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  >div{
    >h4{
      text-align: center;
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 24px;
    }

    p{
      text-align: center;
    }
  }
}

.status-refferal{
  .select-option{
    padding-bottom: 0;

    >div{
      padding-bottom: 0;
    }
  }
}

.compli-table{
  table{

    @include desk{
      min-width: 1380px;
    }
   
    th{
      &:nth-last-child(2){
        min-width: 120px;
      }
    }
  }

}

.sales-table{
  td{
    &:first-child{
      width: 400px;
    }
  }
}

.no-pad-select{
  .select-option{
    padding-bottom: 0;

    >div{
      padding-bottom: 0;
    }
  }
}

.label-font-16 {
  font-size: 16px !important;
}



.free-modal{
  .public-DraftEditor-content{
    min-height: 100px;
  }
}

.img-check{
  max-width: 90px;
  margin-bottom: 12px;
}