:root {
  --white: white;
  --black: #1f303e;
  --text: #658499;
  --btn: #b3fcff;
  --btn-color: #1f303e;
  --hbg: #83fbff;
  --lite: #f2f7f7;
}

.text-dark {
  color: #555e7a !important;
}

.text-text {
  color: #696969 !important;
}

.text-lite {
  color: #e6e8ee !important;
}

.text-black {
  color: #2b2e36 !important;
}

.text-blue {
  color: #4d61aa !important;
}

.text-green {
  color: #13af28 !important;
}

.text-red {
  color: #fb3449 !important;
}

.text-copper {
  color: #d67c14 !important;
}

.bg-blue {
  background-color: #4d61aa !important;
}

.bg-black {
  background-color: #2b2e36 !important;
}

.icon-help {
  display: inline-block;
  width: 31px;
  height: 31px;
  vertical-align: bottom;
}

.blank-btn {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  color: #555e7a;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
}

.blank-blue {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  color: #4d61aa;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
}

.null-btn {
  background: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline-block;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-transition: all 180ms cubic-bezier(0, 0, 0.2, 1);
  transition: all 180ms cubic-bezier(0, 0, 0.2, 1);
}

.null-btn:hover {
  -webkit-transform: scale(1.12);
          transform: scale(1.12);
}

.null-btn.colored {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}

.readmore {
  background: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 180ms cubic-bezier(0, 0, 0.2, 1);
  transition: all 180ms cubic-bezier(0, 0, 0.2, 1);
  text-decoration: underline;
  color: #fb3449;
  font-weight: 600;
}

.icon-btn {
  padding: 4px 10px;
  display: inline-block;
  text-align: center;
  background-color: white;
  border: 1px solid rgba(226, 230, 239, 0.34);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 70px;
  -webkit-transition: all ease 220ms;
  transition: all ease 220ms;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  will-change: transform;
  cursor: pointer;
}

.icon-btn:hover {
  -webkit-box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.11);
          box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.11);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

h1.m0, .m0.h1, h2.m0, .m0.h2, h3.m0, .m0.h3, h4.m0, .m0.h4, h5.m0, .m0.h5, h6.m0, .m0.h6, p.m0, .m0.p {
  margin-bottom: 0px !important;
}

h1.m4, .m4.h1, h2.m4, .m4.h2, h3.m4, .m4.h3, h4.m4, .m4.h4, h5.m4, .m4.h5, h6.m4, .m4.h6, p.m4, .m4.p {
  margin-bottom: 4px !important;
}

h1.m6, .m6.h1, h2.m6, .m6.h2, h3.m6, .m6.h3, h4.m6, .m6.h4, h5.m6, .m6.h5, h6.m6, .m6.h6, p.m6, .m6.p {
  margin-bottom: 6px !important;
}

h1.m8, .m8.h1, h2.m8, .m8.h2, h3.m8, .m8.h3, h4.m8, .m8.h4, h5.m8, .m8.h5, h6.m8, .m8.h6, p.m8, .m8.p {
  margin-bottom: 8px !important;
}

h1.m10, .m10.h1, h2.m10, .m10.h2, h3.m10, .m10.h3, h4.m10, .m10.h4, h5.m10, .m10.h5, h6.m10, .m10.h6, p.m10, .m10.p {
  margin-bottom: 10px !important;
}

h1.m12, .m12.h1, h2.m12, .m12.h2, h3.m12, .m12.h3, h4.m12, .m12.h4, h5.m12, .m12.h5, h6.m12, .m12.h6, p.m12, .m12.p {
  margin-bottom: 12px !important;
}

h1.m16, .m16.h1, h2.m16, .m16.h2, h3.m16, .m16.h3, h4.m16, .m16.h4, h5.m16, .m16.h5, h6.m16, .m16.h6, p.m16, .m16.p {
  margin-bottom: 16px !important;
}

h1.m18, .m18.h1, h2.m18, .m18.h2, h3.m18, .m18.h3, h4.m18, .m18.h4, h5.m18, .m18.h5, h6.m18, .m18.h6, p.m18, .m18.p {
  margin-bottom: 18px !important;
}

h1.m20, .m20.h1, h2.m20, .m20.h2, h3.m20, .m20.h3, h4.m20, .m20.h4, h5.m20, .m20.h5, h6.m20, .m20.h6, p.m20, .m20.p {
  margin-bottom: 20px !important;
}

h1.m24, .m24.h1, h2.m24, .m24.h2, h3.m24, .m24.h3, h4.m24, .m24.h4, h5.m24, .m24.h5, h6.m24, .m24.h6, p.m24, .m24.p {
  margin-bottom: 24px !important;
}

h1.m28, .m28.h1, h2.m28, .m28.h2, h3.m28, .m28.h3, h4.m28, .m28.h4, h5.m28, .m28.h5, h6.m28, .m28.h6, p.m28, .m28.p {
  margin-bottom: 28px !important;
}

h1.m30, .m30.h1, h2.m30, .m30.h2, h3.m30, .m30.h3, h4.m30, .m30.h4, h5.m30, .m30.h5, h6.m30, .m30.h6, p.m30, .m30.p {
  margin-bottom: 30px !important;
}

h1.m32, .m32.h1, h2.m32, .m32.h2, h3.m32, .m32.h3, h4.m32, .m32.h4, h5.m32, .m32.h5, h6.m32, .m32.h6, p.m32, .m32.p {
  margin-bottom: 32px !important;
}

h1.m34, .m34.h1, h2.m34, .m34.h2, h3.m34, .m34.h3, h4.m34, .m34.h4, h5.m34, .m34.h5, h6.m34, .m34.h6, p.m34, .m34.p {
  margin-bottom: 34px !important;
}

h1.m36, .m36.h1, h2.m36, .m36.h2, h3.m36, .m36.h3, h4.m36, .m36.h4, h5.m36, .m36.h5, h6.m36, .m36.h6, p.m36, .m36.p {
  margin-bottom: 36px !important;
}

h1.m38, .m38.h1, h2.m38, .m38.h2, h3.m38, .m38.h3, h4.m38, .m38.h4, h5.m38, .m38.h5, h6.m38, .m38.h6, p.m38, .m38.p {
  margin-bottom: 38px !important;
}

h1.m40, .m40.h1, h2.m40, .m40.h2, h3.m40, .m40.h3, h4.m40, .m40.h4, h5.m40, .m40.h5, h6.m40, .m40.h6, p.m40, .m40.p {
  margin-bottom: 40px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.pt12 {
  padding-top: 12px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.pb12 {
  padding-bottom: 12px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.pt16 {
  padding-top: 16px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.pb16 {
  padding-bottom: 16px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.pl16 {
  padding-left: 16px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.pr16 {
  padding-right: 16px !important;
}

.mt18 {
  margin-top: 18px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.mb18 {
  margin-bottom: 18px !important;
}

.pb18 {
  padding-bottom: 18px !important;
}

.ml18 {
  margin-left: 18px !important;
}

.pl18 {
  padding-left: 18px !important;
}

.mr18 {
  margin-right: 18px !important;
}

.pr18 {
  padding-right: 18px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.pt24 {
  padding-top: 24px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.ml24 {
  margin-left: 24px !important;
}

.pl24 {
  padding-left: 24px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.pr24 {
  padding-right: 24px !important;
}

.mt28 {
  margin-top: 28px !important;
}

.pt28 {
  padding-top: 28px !important;
}

.mb28 {
  margin-bottom: 28px !important;
}

.pb28 {
  padding-bottom: 28px !important;
}

.ml28 {
  margin-left: 28px !important;
}

.pl28 {
  padding-left: 28px !important;
}

.mr28 {
  margin-right: 28px !important;
}

.pr28 {
  padding-right: 28px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.pt32 {
  padding-top: 32px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.pb32 {
  padding-bottom: 32px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.pl32 {
  padding-left: 32px !important;
}

.mr32 {
  margin-right: 32px !important;
}

.pr32 {
  padding-right: 32px !important;
}

.mt36 {
  margin-top: 36px !important;
}

.pt36 {
  padding-top: 36px !important;
}

.mb36 {
  margin-bottom: 36px !important;
}

.pb36 {
  padding-bottom: 36px !important;
}

.ml36 {
  margin-left: 36px !important;
}

.pl36 {
  padding-left: 36px !important;
}

.mr36 {
  margin-right: 36px !important;
}

.pr36 {
  padding-right: 36px !important;
}

.mt38 {
  margin-top: 38px !important;
}

.pt38 {
  padding-top: 38px !important;
}

.mb38 {
  margin-bottom: 38px !important;
}

.pb38 {
  padding-bottom: 38px !important;
}

.ml38 {
  margin-left: 38px !important;
}

.pl38 {
  padding-left: 38px !important;
}

.mr38 {
  margin-right: 38px !important;
}

.pr38 {
  padding-right: 38px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.flex-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.fw100 {
  font-weight: 100 !important;
}

.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

.fs10 {
  font-size: 10px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs26 {
  font-size: 26px !important;
}

.va1 {
  vertical-align: 1px !important;
}

.va2 {
  vertical-align: 2px !important;
}

.va3 {
  vertical-align: 3px !important;
}

.va4 {
  vertical-align: 4px !important;
}

.va0 {
  vertical-align: 0px !important;
}

.va-1 {
  vertical-align: -1px !important;
}

.va-2 {
  vertical-align: -2px !important;
}

.va-3 {
  vertical-align: -3px !important;
}

.va-4 {
  vertical-align: -4px !important;
}

.lh12 {
  line-height: 1.2 !important;
}

.lh13 {
  line-height: 1.3 !important;
}

.lh14 {
  line-height: 1.5 !important;
}

.lh15 {
  line-height: 1.5 !important;
}

.lh16 {
  line-height: 1.6 !important;
}

img {
  max-width: 100%;
}

.btn-grp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.btn-grp.bg-grey {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: rgba(239, 239, 239, 0.5);
  border-radius: 10px;
  padding: 6px;
}

.btn-grp.bg-grey a,
.btn-grp.bg-grey button,
.btn-grp.bg-grey div {
  margin-left: 10px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .btn-grp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.btn-grp.mt60 {
  margin-top: 60px;
}

.btn-grp a,
.btn-grp button,
.btn-grp div {
  margin-top: 10px;
}

@media (max-width: 767px) {
  .btn-grp a,
  .btn-grp button,
  .btn-grp div {
    width: auto;
  }
}

@media (min-width: 767px) {
  .btn-grp a,
  .btn-grp button,
  .btn-grp div {
    margin-left: 16px;
    margin-top: 0;
  }
}

.btn-grp a:first-child,
.btn-grp button:first-child,
.btn-grp div:first-child {
  margin-left: 0;
}

@media (max-width: 767px) {
  .btn-grp a:first-child,
  .btn-grp button:first-child,
  .btn-grp div:first-child {
    margin-top: 0;
  }
}

.btn-grp.center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.updown {
  -webkit-animation: updown 3000ms cubic-bezier(0.18, -0.03, 0.83, 1.02) 100ms infinite alternate;
          animation: updown 3000ms cubic-bezier(0.18, -0.03, 0.83, 1.02) 100ms infinite alternate;
  will-change: transform;
}

@-webkit-keyframes updown {
  0% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -3px, 0);
            transform: translate3d(0, -3px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
}

@keyframes updown {
  0% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -3px, 0);
            transform: translate3d(0, -3px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
}

.updown-sm {
  -webkit-animation: updown-sm 2800ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02) infinite alternate;
          animation: updown-sm 2800ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02) infinite alternate;
  will-change: transform;
}

@-webkit-keyframes updown-sm {
  0% {
    -webkit-transform: translate3d(0, -3px, 0);
            transform: translate3d(0, -3px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 1px, 0);
            transform: translate3d(0, 1px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -3px, 0);
            transform: translate3d(0, -3px, 0);
  }
}

@keyframes updown-sm {
  0% {
    -webkit-transform: translate3d(0, -3px, 0);
            transform: translate3d(0, -3px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 1px, 0);
            transform: translate3d(0, 1px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -3px, 0);
            transform: translate3d(0, -3px, 0);
  }
}

.updown-lg {
  -webkit-animation: updown-lg 3200ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02) infinite alternate;
          animation: updown-lg 3200ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02) infinite alternate;
  will-change: transform;
}

@-webkit-keyframes updown-lg {
  0% {
    -webkit-transform: translate3d(0, -6px, 0);
            transform: translate3d(0, -6px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 3px, 0);
            transform: translate3d(0, 3px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -6px, 0);
            transform: translate3d(0, -6px, 0);
  }
}

@keyframes updown-lg {
  0% {
    -webkit-transform: translate3d(0, -6px, 0);
            transform: translate3d(0, -6px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 3px, 0);
            transform: translate3d(0, 3px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -6px, 0);
            transform: translate3d(0, -6px, 0);
  }
}

.updown-xl {
  -webkit-animation: updown-xl 4000ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02) infinite alternate;
          animation: updown-xl 4000ms 100ms cubic-bezier(0.18, -0.03, 0.83, 1.02) infinite alternate;
  will-change: transform;
}

@-webkit-keyframes updown-xl {
  0% {
    -webkit-transform: translate3d(0, 7px, 0);
            transform: translate3d(0, 7px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 7px, 0);
            transform: translate3d(0, 7px, 0);
  }
}

@keyframes updown-xl {
  0% {
    -webkit-transform: translate3d(0, 7px, 0);
            transform: translate3d(0, 7px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -4px, 0);
            transform: translate3d(0, -4px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 7px, 0);
            transform: translate3d(0, 7px, 0);
  }
}

.loader-cicle {
  content: "";
  position: relative;
  border-radius: 50%;
  border: 4px solid #2b2e36;
  border-right-color: transparent;
  -webkit-animation: spin 600ms linear infinite forwards;
          animation: spin 600ms linear infinite forwards;
  width: 28px;
  height: 28px;
  z-index: 10;
  display: inline-block;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.ml-div {
  margin-left: 0;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .ml-div {
    margin-left: auto;
    margin-top: 0px;
  }
}

.h300 {
  height: 300px;
}

figure {
  display: block;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
}

[type="time"] {
  position: relative;
}

[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="file"],
input[type="date"],
input[type="time"],
textarea,
.MuiSelect-select,
select,
.MuiInputBase-multiline {
  width: 100%;
  background: transparent;
  border: 1px solid #d7d3d3;
  border-radius: 4px;
  color: #2b2e36;
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  min-height: auto !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: auto;
}

@media (min-width: 767px) {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="file"],
  input[type="date"],
  input[type="time"],
  textarea,
  .MuiSelect-select,
  select,
  .MuiInputBase-multiline {
    font-size: 16px;
    padding: 11px 15px;
    line-height: 1.38;
  }
}

input[type="text"].error,
input[type="tel"].error,
input[type="email"].error,
input[type="password"].error,
input[type="number"].error,
input[type="file"].error,
input[type="date"].error,
input[type="time"].error,
textarea.error,
.MuiSelect-select.error,
select.error,
.MuiInputBase-multiline.error {
  border-color: #fb3449;
}

input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="file"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.MuiSelect-select::-webkit-input-placeholder,
select::-webkit-input-placeholder,
.MuiInputBase-multiline::-webkit-input-placeholder {
  color: #8992a9;
}

input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="file"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
.MuiSelect-select:-ms-input-placeholder,
select:-ms-input-placeholder,
.MuiInputBase-multiline:-ms-input-placeholder {
  color: #8992a9;
}

input[type="text"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder,
input[type="file"]::-ms-input-placeholder,
input[type="date"]::-ms-input-placeholder,
input[type="time"]::-ms-input-placeholder,
textarea::-ms-input-placeholder,
.MuiSelect-select::-ms-input-placeholder,
select::-ms-input-placeholder,
.MuiInputBase-multiline::-ms-input-placeholder {
  color: #8992a9;
}

input[type="text"]::placeholder,
input[type="tel"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
input[type="file"]::placeholder,
input[type="date"]::placeholder,
input[type="time"]::placeholder,
textarea::placeholder,
.MuiSelect-select::placeholder,
select::placeholder,
.MuiInputBase-multiline::placeholder {
  color: #8992a9;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="file"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
textarea:focus,
.MuiSelect-select:focus,
select:focus,
.MuiInputBase-multiline:focus {
  outline: none;
  border-color: #4d61aa;
}

input[type="text"].error,
input[type="tel"].error,
input[type="email"].error,
input[type="password"].error,
input[type="number"].error,
input[type="file"].error,
input[type="date"].error,
input[type="time"].error,
textarea.error,
.MuiSelect-select.error,
select.error,
.MuiInputBase-multiline.error {
  border-color: #fb3449;
}

input[type="text"]:disabled, input[type="text"].disabled,
input[type="tel"]:disabled,
input[type="tel"].disabled,
input[type="email"]:disabled,
input[type="email"].disabled,
input[type="password"]:disabled,
input[type="password"].disabled,
input[type="number"]:disabled,
input[type="number"].disabled,
input[type="file"]:disabled,
input[type="file"].disabled,
input[type="date"]:disabled,
input[type="date"].disabled,
input[type="time"]:disabled,
input[type="time"].disabled,
textarea:disabled,
textarea.disabled,
.MuiSelect-select:disabled,
.MuiSelect-select.disabled,
select:disabled,
select.disabled,
.MuiInputBase-multiline:disabled,
.MuiInputBase-multiline.disabled {
  pointer-events: none;
  background-color: whitesmoke;
}

input[type="text"].sm-input,
input[type="tel"].sm-input,
input[type="email"].sm-input,
input[type="password"].sm-input,
input[type="number"].sm-input,
input[type="file"].sm-input,
input[type="date"].sm-input,
input[type="time"].sm-input,
textarea.sm-input,
.MuiSelect-select.sm-input,
select.sm-input,
.MuiInputBase-multiline.sm-input {
  padding: 4px;
  width: auto;
  font-size: 14px;
}

@media (min-width: 767px) {
  input[type="text"].sm-input,
  input[type="tel"].sm-input,
  input[type="email"].sm-input,
  input[type="password"].sm-input,
  input[type="number"].sm-input,
  input[type="file"].sm-input,
  input[type="date"].sm-input,
  input[type="time"].sm-input,
  textarea.sm-input,
  .MuiSelect-select.sm-input,
  select.sm-input,
  .MuiInputBase-multiline.sm-input {
    padding: 5px 4px 4px;
    width: auto;
    font-size: 14px;
  }
}

select {
  width: 100%;
  background: transparent;
  background: transparent url(../images/icons/caret-down-icon.svg) no-repeat;
  background-position: calc(100% - 14px) 20px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

select::-webkit-input-placeholder {
  color: #696969;
}

select:-ms-input-placeholder {
  color: #696969;
}

select::-ms-input-placeholder {
  color: #696969;
}

select::placeholder {
  color: #696969;
}

select:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="date"],
input[type="time"] {
  padding: 11px 10px;
}

textarea {
  border: 1px solid #9da3b6;
  padding: 14px 14px 14px;
  line-height: 1.4;
}

.date-input input {
  background: white url(../images/icons/calendar-red-icon.svg) no-repeat;
  background-position: calc(100% - 12px) center;
}

.date-input > .react-datepicker-wrapper {
  min-width: 160px;
}

.form-in {
  position: relative;
  text-align: left;
}

.form-in .MuiInput-formControl {
  margin-top: 0;
}

.form-in .MuiTextField-root {
  width: 100%;
}

.form-in [class*="container"] [class*="control"] {
  min-height: 46px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-in [class*="container"] [class*="control"] [class*="indicatorContainer"]:last-child {
  background: url(../images/icons/caret-down-icon.svg) no-repeat center;
}

.form-in [class*="container"] [class*="control"] [class*="indicatorContainer"]:last-child svg {
  opacity: 0;
}

.form-in [class*="container"] [class*="control"]:hover, .form-in [class*="container"] [class*="control"]:focus {
  border-color: #4d61aa;
}

.form-in [class*="container"] [class*="control"] > div:first-child,
.form-in [class*="container"] [class*="control"] [class*="ValueContainer"] {
  padding: 2px 15px;
  font-size: 14px;
  font-size: 16px;
  line-height: 1.38;
}

.form-in [class*="container"] [class*="menu"] {
  font-size: 12px;
}

.form-in [class*="container"] [class*="indicatorSeparator"] {
  display: none;
}

.form-in.date-pick label {
  -webkit-transform: translate(14px, 17px) scale(1);
          transform: translate(14px, 17px) scale(1);
  font-size: 14px;
  z-index: 2;
  top: 0;
  left: 0;
  position: absolute;
}

.form-in.date-pick label.Mui-focused, .form-in.date-pick label.MuiInputLabel-shrink {
  -webkit-transform: translate(12px, -5px) scale(0.8);
          transform: translate(12px, -5px) scale(0.8);
  background-color: white;
}

.form-in > label {
  font-size: 12px;
  margin-bottom: 6px;
  display: block;
  color: #696969;
}

.form-in fieldset {
  border: none;
}

.form-in p.Mui-error {
  margin: 3px 0 0;
}

.form-in .MuiInputBase-formControl {
  line-height: 1;
}

.form-in.phone .f-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-in.phone .f-in .react-tel-input select,
.form-in.phone .f-in .react-tel-input input {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 80px;
  height: auto;
  background: url(../images/icons/caret-down-icon.svg) no-repeat;
  background-position: calc(100% - 12px) 19px;
  line-height: 1;
  padding: 13px 14px;
}

.form-in.phone .f-in .react-tel-input .flag-dropdown {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: transparent;
}

.form-in.phone .f-in .react-tel-input .selected-flag {
  opacity: 0;
  width: 100%;
}

.form-in.phone .f-in > div:nth-child(2) {
  max-width: 120px;
  padding-right: 10px;
}

.form-in.phone .f-in > .MuiTextField-root input {
  padding-left: 16px !important;
}

.form-in.phone .f-in input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-in.phone .code {
  max-width: 130px;
  padding-right: 10px;
}

.form-in.phone .pl {
  padding-left: 12px;
}

.form-in .MuiSelect-select {
  border: 1px solid #d7d3d3;
}

.form-in.grey input,
.form-in.grey .MuiSelect-select,
.form-in.grey .MuiInputBase-multiline {
  background-color: #f1eded;
}

.form-in.grey input textarea,
.form-in.grey .MuiSelect-select textarea,
.form-in.grey .MuiInputBase-multiline textarea {
  background-color: transparent;
}

.form-in.grey [class*="container"] [class*="control"] {
  background-color: #f1eded;
}

.form-in.icon .icon-left {
  position: absolute;
  top: 12px;
  left: 8px;
  z-index: 10;
}

@media (min-width: 480px) {
  .form-in.icon .icon-left {
    left: 10px;
  }
}

@media (min-width: 767px) {
  .form-in.icon .icon-left {
    top: 10px;
  }
}

.form-in.icon input,
.form-in.icon .MuiSelect-select,
.form-in.icon .MuiInputBase-multiline {
  padding-left: 38px;
}

@media (min-width: 480px) {
  .form-in.icon input,
  .form-in.icon .MuiSelect-select,
  .form-in.icon .MuiInputBase-multiline {
    padding-left: 40px;
  }
}

.form-in.icon .f-in label:not(.Mui-focused) {
  -webkit-transform: translate(42px, 14px) scale(1) !important;
          transform: translate(42px, 14px) scale(1) !important;
}

.form-in.icon .f-in label.MuiInputLabel-shrink, .form-in.icon .f-in label.MuiFormLabel-filled {
  -webkit-transform: translate(14px, -7px) scale(0.6) !important;
          transform: translate(14px, -7px) scale(0.6) !important;
  background-color: #fff0e3;
  padding: 0px 4px;
}

.form-in.icon [class*="container"] [class*="control"] {
  opacity: 1;
}

.form-in.icon [class*="container"] [class*="control"] [class*="ValueContainer"], .form-in.icon [class*="container"] [class*="control"] > div:first-child {
  padding-left: 40px;
}

.form-in.right-icon input {
  padding-right: 40px;
}

.form-in:has(.icon-right) input {
  padding-right: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-in .icon-right {
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 2;
  background: transparent;
  border: none;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
}

.form-in .icon-right.text {
  font-size: 14px;
  top: 14px;
  right: 14px;
}

.form-in .icon-right .MuiIconButton-root {
  padding: 0;
}

@media (min-width: 767px) {
  .form-in .icon-right {
    top: 12px;
  }
}

.form-in .icon-right:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.form-in:not(:last-child) {
  margin-bottom: 16px;
}

.form-in .f-in {
  position: relative;
}

.form-in .f-in.otp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.form-in .f-in.otp input {
  width: 60px;
  margin: 0 10px;
  text-align: center;
  padding: 14px 10px;
}

.form-in .f-in .MuiFormControl-root {
  width: 100%;
}

.form-in .f-in label {
  font-size: 14px;
  color: #696969;
  margin-bottom: 8px;
}

@media (min-width: 767px) {
  .form-in .f-in label {
    font-size: 14px;
  }
}

.form-in .f-in label:not(.Mui-focused) {
  -webkit-transform: translate(14px, 15px) scale(1);
          transform: translate(14px, 15px) scale(1);
}

.form-in .f-in label.MuiInputLabel-shrink, .form-in .f-in label.MuiFormLabel-filled {
  -webkit-transform: translate(14px, -8px) scale(0.6) !important;
          transform: translate(14px, -8px) scale(0.6) !important;
  background-color: #fff0e3;
  padding: 0px 4px;
}

.form-in .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4d61aa;
}

.form-in.error input,
.form-in.error select,
.form-in.error textarea {
  border-color: #fb3449;
}

.form-in.error .error-msg {
  display: block;
}

.form-in.icon .custom-label:not(.Mui-focused) {
  position: absolute;
  background-color: white;
  padding: 0px 4px;
  top: -11px;
  z-index: 2;
  left: -9px;
  -webkit-transform: scale(0.6) !important;
          transform: scale(0.6) !important;
  color: #696969;
}

.date-pick input {
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1.15;
}

.error-msg {
  color: #fb3449;
  font-size: 14px;
  margin-top: 4px;
}

.MuiInputBase-root.error input {
  border-color: #fb3449;
}

.MuiInput-underline:before, .MuiInput-underline:after {
  display: none;
}

.otpContainer .otp-input input {
  min-width: 50px;
  font-size: 16px;
}

.MuiSwitch-sizeMedium.MuiSwitch-root {
  position: relative;
  height: auto;
  width: auto;
  padding: 0px;
  -webkit-box-shadow: 3px 2px 6px rgba(6, 6, 6, 0.12);
          box-shadow: 3px 2px 6px rgba(6, 6, 6, 0.12);
  border-radius: 50px;
}

.MuiSwitch-sizeMedium.MuiSwitch-root .MuiSwitch-switchBase {
  position: absolute;
  padding: 2.6px;
  background-color: transparent;
}

.MuiSwitch-sizeMedium.MuiSwitch-root .MuiSwitch-switchBase .MuiSwitch-thumb {
  background-color: #696969;
}

.MuiSwitch-sizeMedium.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: white;
}

.MuiSwitch-sizeMedium.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #4d61aa;
  opacity: 1;
}

.MuiSwitch-sizeMedium.MuiSwitch-root .MuiSwitch-track {
  height: 25px;
  width: 46px;
  padding: 0px;
  border-radius: 50px;
  background-color: whitesmoke;
  left: 0;
  display: inline-block;
  opacity: 1;
}

.switch {
  position: relative;
  display: inline-block;
}

.switch > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.switch > div label {
  font-weight: 500;
  padding-left: 8px;
  margin-bottom: 0px;
  font-size: 14px;
}

.switch .slide {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 46px;
  height: 24px;
  background: rgba(211, 211, 211, 0.565);
  border-radius: 82px;
  padding: 3px;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
}

.switch .slide:before {
  content: "";
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  background: #696969;
  -webkit-box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.08);
          box-shadow: 0px -1px 7px rgba(0, 0, 0, 0.08);
  border-radius: 82px;
  -webkit-transition: all ease 200ms;
  transition: all ease 200ms;
}

.switch input {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.switch input:checked + div .slide {
  background-color: #4d61aa;
}

.switch input:checked + div .slide:before {
  translate: 20px 0px;
  background-color: white;
}

.checkbox {
  position: relative;
  display: inline-block;
}

.checkbox label {
  position: relative;
  margin-bottom: 0;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  color: #2b2e36;
}

.checkbox label:before {
  content: "";
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  background: url(../images/icons/check-white.svg) no-repeat center;
  background-position: 15px 0px;
  background-color: #ffffff;
  border: 1px solid #a6b0c2;
  border-radius: 4px;
  margin-right: 10px;
  background-size: 14px;
}

.checkbox input {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.checkbox input:checked + label {
  color: #2b2e36;
  font-weight: 600;
}

.checkbox input:checked + label:before {
  background-color: #4d61aa;
  border-color: #4d61aa;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(77, 97, 170, 0.32);
          box-shadow: 0px 0px 0px 1px rgba(77, 97, 170, 0.32);
  background-position: center;
}

.MuiRadio-root.Mui-checked svg path {
  fill: #4d61aa;
}

.radio {
  position: relative;
  display: inline-block;
  padding: 15px 0 16px;
}

.radio label {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: #555e7a;
}

.radio label:before {
  content: "";
  width: 20px;
  height: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
  display: inline-block;
  background-position: 20px;
  background-size: 12px;
  background-color: #ffffff;
  border: 1px solid #c8ccd7;
  border-radius: 50%;
  margin-right: 10px;
  top: 0px;
}

.radio input {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.radio input:checked + label {
  color: #555e7a;
}

.radio input:checked + label:before {
  background-position: center;
  background-color: #4d61aa;
  border: 1px solid #4d61aa;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(77, 97, 170, 0.12);
          box-shadow: 0px 0px 0px 1px rgba(77, 97, 170, 0.12);
}

.radio.sm label {
  font-size: 14px;
}

@-webkit-keyframes click {
  0%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}

@keyframes click {
  0%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid #4d61aa;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  white-space: nowrap;
  background-color: #4d61aa;
  color: white;
}

@media (min-width: 767px) {
  .btn {
    font-size: 14px;
    padding: 11px 24px;
  }
}

.btn [class*="icon"] {
  margin-right: 6px;
}

.btn.sm {
  padding: 6px 12px;
  min-width: auto;
  font-size: 12px;
}

.btn.fs16 {
  line-height: 1.38;
}

.btn.small {
  padding: 6px 20px;
}

.btn.long {
  min-width: 250px;
  font-weight: 700;
  font-size: 16px;
}

.btn:hover, .btn.active {
  color: white;
  background-color: #3d4d87;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
}

.btn:focus {
  -webkit-animation: click 290ms ease forwards;
          animation: click 290ms ease forwards;
}

.btn.disabled,
.btn:disabled {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid #4d61aa;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  white-space: nowrap;
  background-color: #dadada;
  border-color: #dadada;
  color: #929191;
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (min-width: 767px) {
  .btn.disabled,
  .btn:disabled {
    font-size: 14px;
    padding: 11px 24px;
  }
}

.btn.disabled [class*="icon"],
.btn:disabled [class*="icon"] {
  margin-right: 6px;
}

.btn.disabled.sm,
.btn:disabled.sm {
  padding: 6px 12px;
  min-width: auto;
  font-size: 12px;
}

.btn.disabled.fs16,
.btn:disabled.fs16 {
  line-height: 1.38;
}

.btn.disabled.small,
.btn:disabled.small {
  padding: 6px 20px;
}

.btn.disabled.long,
.btn:disabled.long {
  min-width: 250px;
  font-weight: 700;
  font-size: 16px;
}

.btn.disabled:hover, .btn.disabled.active,
.btn:disabled:hover,
.btn:disabled.active {
  color: white;
  background-color: #3d4d87;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
}

.btn.disabled:focus,
.btn:disabled:focus {
  -webkit-animation: click 290ms ease forwards;
          animation: click 290ms ease forwards;
}

.btn.disabled:hover,
.btn:disabled:hover {
  background-color: #dadada;
  border-color: #dadada;
  color: #696969;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-blank {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  white-space: nowrap;
  background-color: transparent;
  color: #696969;
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (min-width: 767px) {
  .btn-blank {
    font-size: 14px;
    padding: 11px 24px;
  }
}

.btn-blank [class*="icon"] {
  margin-right: 6px;
}

.btn-blank.sm {
  padding: 6px 12px;
  min-width: auto;
  font-size: 12px;
}

.btn-blank.fs16 {
  line-height: 1.38;
}

.btn-blank.small {
  padding: 6px 20px;
}

.btn-blank.long {
  min-width: 250px;
  font-weight: 700;
  font-size: 16px;
}

.btn-blank:hover, .btn-blank.active {
  color: white;
  background-color: #505050;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
}

.btn-blank:focus {
  -webkit-animation: click 290ms ease forwards;
          animation: click 290ms ease forwards;
}

.btn-white {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid #4d61aa;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  white-space: nowrap;
  background-color: transparent;
  color: #4d61aa;
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (min-width: 767px) {
  .btn-white {
    font-size: 14px;
    padding: 11px 24px;
  }
}

.btn-white [class*="icon"] {
  margin-right: 6px;
}

.btn-white.sm {
  padding: 6px 12px;
  min-width: auto;
  font-size: 12px;
}

.btn-white.fs16 {
  line-height: 1.38;
}

.btn-white.small {
  padding: 6px 20px;
}

.btn-white.long {
  min-width: 250px;
  font-weight: 700;
  font-size: 16px;
}

.btn-white:hover, .btn-white.active {
  color: white;
  background-color: #3d4d87;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
}

.btn-white:focus {
  -webkit-animation: click 290ms ease forwards;
          animation: click 290ms ease forwards;
}

.btn-white.disabled, .btn-white:disabled {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid #4d61aa;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  white-space: nowrap;
  background-color: #dadada;
  border-color: #dadada;
  color: #929191;
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (min-width: 767px) {
  .btn-white.disabled, .btn-white:disabled {
    font-size: 14px;
    padding: 11px 24px;
  }
}

.btn-white.disabled [class*="icon"], .btn-white:disabled [class*="icon"] {
  margin-right: 6px;
}

.btn-white.disabled.sm, .btn-white:disabled.sm {
  padding: 6px 12px;
  min-width: auto;
  font-size: 12px;
}

.btn-white.disabled.fs16, .btn-white:disabled.fs16 {
  line-height: 1.38;
}

.btn-white.disabled.small, .btn-white:disabled.small {
  padding: 6px 20px;
}

.btn-white.disabled.long, .btn-white:disabled.long {
  min-width: 250px;
  font-weight: 700;
  font-size: 16px;
}

.btn-white.disabled:hover, .btn-white.disabled.active, .btn-white:disabled:hover, .btn-white:disabled.active {
  color: white;
  background-color: #3d4d87;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
}

.btn-white.disabled:focus, .btn-white:disabled:focus {
  -webkit-animation: click 290ms ease forwards;
          animation: click 290ms ease forwards;
}

.btn-white.disabled:hover, .btn-white:disabled:hover {
  background-color: #dadada;
  border-color: #dadada;
  color: #696969;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.29;
  padding: 11px 24px;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid rgba(77, 97, 170, 0);
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  white-space: nowrap;
  background-color: rgba(77, 97, 170, 0.1);
  color: #4d61aa;
  -webkit-box-shadow: none;
          box-shadow: none;
  pointer-events: none;
}

@media (min-width: 767px) {
  .btn-label {
    font-size: 14px;
    padding: 11px 24px;
  }
}

.btn-label [class*="icon"] {
  margin-right: 6px;
}

.btn-label.sm {
  padding: 6px 12px;
  min-width: auto;
  font-size: 12px;
}

.btn-label.fs16 {
  line-height: 1.38;
}

.btn-label.small {
  padding: 6px 20px;
}

.btn-label.long {
  min-width: 250px;
  font-weight: 700;
  font-size: 16px;
}

.btn-label:hover, .btn-label.active {
  color: white;
  background-color: #3d4d87;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
}

.btn-label:focus {
  -webkit-animation: click 290ms ease forwards;
          animation: click 290ms ease forwards;
}

.user-icon {
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/user-icon.svg) no-repeat center;
}

.dashboard-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/dashboard-icon.svg) no-repeat center;
}

.setting-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/setting-icon.svg) no-repeat center;
}

.help-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/help-icon.svg) no-repeat center;
}

.logout-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/logout-icon.svg) no-repeat center;
}

.search-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/search-icon.svg) no-repeat center;
}

.edit-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/edit-icon.svg) no-repeat center;
}

.delete-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/delete-icon.svg) no-repeat center;
}

.view-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/view-icon.svg) no-repeat center;
}

.mail-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/mail-icon.svg) no-repeat center;
}

.password-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/password-icon.svg) no-repeat center;
}

.upload-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/upload-icon.svg) no-repeat center;
}

.bookmark-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/bookmark-icon.svg) no-repeat center;
}

.share-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/share-icon.svg) no-repeat center;
}

.right-caret-icon {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/right-caret-icon.svg) no-repeat center;
}

.back-icon {
  width: 8px;
  height: 8px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/back-icon.svg) no-repeat center;
}

.delete-white-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/delete-white-icon.svg) no-repeat center;
}

.edit-blue-icon {
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/edit-blue-icon.svg) no-repeat center;
}

.lock-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/lock-icon.svg) no-repeat center;
}

.block-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/block-icon.svg) no-repeat center;
}

.user-sm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/user-sm-icon.svg) no-repeat center;
}

.flag-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/flag-icon.svg) no-repeat center;
}

.phone-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/phone-icon.svg) no-repeat center;
}

.facebook-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/facebook-icon.svg) no-repeat center;
}

.twitter-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/twitter-icon.svg) no-repeat center;
}

.linkedin-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/linkedin-icon.svg) no-repeat center;
}

.insta-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/insta-icon.svg) no-repeat center;
}

.percentage-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/percentage-icon.svg) no-repeat center;
}

.text-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/text-icon.svg) no-repeat center;
}

.check-green-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/check-green-icon.svg) no-repeat center;
}

.cross-red-icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/cross-red-icon.svg) no-repeat center;
}

.plus-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/plus-icon.svg) no-repeat center;
}

.minus-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/minus-icon.svg) no-repeat center;
}

.dollar-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/dollar-icon.svg) no-repeat center;
}

.caret-right-black-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/caret-right-black-icon.svg) no-repeat center;
}

.caret-left-black-icon {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/caret-left-black-icon.svg) no-repeat center;
}

.trash-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/trash-icon.svg) no-repeat center;
}

.coupon-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/coupon-icon.svg) no-repeat center;
}

.caret-sm-icon {
  width: 5px;
  height: 5px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/caret-sm-icon.svg) no-repeat center;
}

.green-flag-icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/green-flag-icon.svg) no-repeat center;
}

.red-flag-icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/red-flag-icon.svg) no-repeat center;
}

.msg-icon {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/msg-icon.svg) no-repeat center;
}

.rupee-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/rupee-icon.svg) no-repeat center;
}

.copy-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  background: url(../images/icons/copy-icon.svg) no-repeat center;
}

.edit-blue-icon {
  background-size: contain;
}

.rupee-icon {
  background-size: contain;
  opacity: 0.8;
}

.user-icon.sm {
  background-size: contain;
  width: 18px;
}

.font-old {
  font-family: "Open Sans", sans-serif;
}

.italic {
  font-style: italic;
}

body {
  margin: 0;
  line-height: 1.2;
  color: #2b2e36;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-ExtraBold.ttf") format("ttf"), url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-SemiBold.ttf") format("ttf"), url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-SemiBoldItalic.ttf") format("ttf"), url("../fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-stretch: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Medium.ttf") format("ttf"), url("../fonts/OpenSans-Medium.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Regular.ttf") format("ttf"), url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Italic.ttf") format("ttf"), url("../fonts/OpenSans-Italic.ttf") format("truetype");
  font-style: italic;
  font-stretch: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-Light.ttf") format("ttf"), url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/OpenSans-LightItalic.ttf") format("ttf"), url("../fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-stretch: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Old Standard';
  src: url("../fonts/old-standard-tt.bold.ttf") format("ttf"), url("../fonts//old-standard-tt.bold.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Old Standard';
  src: url("../fonts/old-standard-tt.regular.ttf") format("ttf"), url("../fonts/old-standard-tt.regular.ttf") format("truetype");
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
}

h1, .h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 12px;
  margin-top: 0;
  font-family: "Open Sans", sans-serif;
}

@media (min-width: 767px) {
  h1, .h1 {
    font-size: 42px;
    line-height: 1.09;
    margin-bottom: 16px;
  }
}

@media (min-width: 767px) {
  h1.fs56, .h1.fs56 {
    font-size: 52px;
    line-height: 1.1;
  }
}

h2, .h2 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 26px;
  line-height: 1.24;
  font-family: "Open Sans", sans-serif;
}

@media (min-width: 767px) {
  h2, .h2 {
    font-size: 42px;
  }
}

h3, .h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.18;
  font-family: "Open Sans", sans-serif;
  margin-top: 0;
}

@media (min-width: 767px) {
  h3, .h3 {
    font-size: 34px;
  }
}

h4, .h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 16px;
}

@media (min-width: 767px) {
  h4, .h4 {
    font-size: 28px;
    line-height: 1.25;
  }
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 32px;
  }
}

h5, .h5 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 15px;
  margin-top: 0;
}

@media (min-width: 767px) {
  h5, .h5 {
    font-size: 24px;
  }
  h5.fs28, .h5.fs28 {
    font-size: 28px;
    line-height: 1.07;
  }
}

h6, .h6 {
  line-height: 1.33;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 6px;
  letter-spacing: -0.1px;
  margin-top: 0;
}

@media (min-width: 767px) {
  h6, .h6 {
    font-size: 18px;
  }
}

p, .p {
  font-size: 14px;
  line-height: 1.45;
  color: #2b2e36;
  margin: 0;
  margin-top: 0;
}

@media (min-width: 992px) {
  p, .p {
    font-size: 14px;
  }
}

p.sm, .p.sm {
  font-size: 14px;
  line-height: 1.37;
}

p.txt, .p.txt {
  font-weight: 600;
  color: #2b2e36;
}

p.lg, .p.lg {
  color: #2b2e36;
}

@media (min-width: 767px) {
  p.lg, .p.lg {
    font-size: 16px;
  }
}

p b, .p b {
  font-weight: 600;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 24px;
}

ul li {
  margin-bottom: 12px;
  line-height: 1.5;
}

@media (min-width: 992px) {
  ul li {
    font-size: 16px;
  }
}

.subtext {
  font-size: 14px;
  line-height: 1.14;
  color: #c8ccd7;
}

a {
  color: #2b2e36;
  text-decoration: none;
  outline: none !important;
}

a:hover {
  color: #2b2e36;
  text-decoration: none;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  color: #2b2e36;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.row > div {
  padding: 0 10px;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-block {
  display: block;
}

@media (min-width: 1300px) {
  .text-xl-right {
    text-align: end;
  }
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-offset-1 {
  margin-left: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-offset-2 {
  margin-left: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-offset-3 {
  margin-left: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-offset-4 {
  margin-left: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-offset-5 {
  margin-left: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-offset-6 {
  margin-left: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-offset-7 {
  margin-left: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-offset-8 {
  margin-left: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-offset-9 {
  margin-left: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-offset-10 {
  margin-left: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-offset-11 {
  margin-left: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.col-offset-12 {
  margin-left: 100%;
}

@media screen and (min-width: 576px) {
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 768px) {
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 992px) {
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1300px) {
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

@media screen and (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }
}

@media screen and (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
}

@media screen and (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }
}

@media screen and (min-width: 1300px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-block {
    display: block !important;
  }
}

.All-in {
  background-color: white;
  min-height: 100vh;
}

.wrapper {
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.main-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1050;
  background-color: #f1ddcc;
}

.main-header nav.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px 0 0;
}

@media (min-width: 992px) {
  .main-header nav.navbar {
    padding: 0 24px 0 0;
  }
}

.main-header a.navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #2b2e36;
  position: relative;
  background-color: #fff0e3;
  padding: 18px 12px;
  width: 210px;
  text-align: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.main-header a.navbar-brand img {
  max-width: 140px;
  margin: 0 auto;
}

.main-header ul.navbar-nav {
  margin-left: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.main-header ul.navbar-nav li {
  margin: 0 15px;
}

@media (min-width: 992px) {
  .main-header ul.navbar-nav li {
    margin: 0 20px;
  }
}

.main-header ul.navbar-nav li:last-child {
  margin-right: 0;
}

.main-header ul.navbar-nav li a {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.main-header .hamBtn {
  margin-right: 10px;
  position: relative;
  display: inline-block;
  display: none;
}

.main-header .hamBtn span {
  width: 18px;
  height: 1px;
  display: block;
  background-color: #2b2e36;
  margin: 4px 0;
}

.nav-menu .MuiMenu-paper {
  padding: 8px 0px;
  background-color: #fff0e3;
}

.nav-menu .MuiMenu-paper ul li {
  min-height: auto;
  font-size: 14px;
  color: #2b2e36;
}

@media (min-width: 767px) {
  .nav-menu .MuiMenu-paper ul li {
    font-size: 16px;
  }
}

.dashboardSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

aside {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 65px;
          flex: 1 0 65px;
  max-width: 65px;
  min-height: 100vh;
  background-color: #f1ddcc;
  -webkit-box-shadow: 1px 0px 0px #e7e7e7;
          box-shadow: 1px 0px 0px #e7e7e7;
  -webkit-transition: all ease 250ms;
  transition: all ease 250ms;
  -webkit-transition-delay: 250ms;
          transition-delay: 250ms;
}

aside.active {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 210px;
          flex: 1 0 210px;
  max-width: 210px;
  min-height: 100vh;
  background-color: #f1ddcc;
  -webkit-box-shadow: 1px 0px 0px #e7e7e7;
          box-shadow: 1px 0px 0px #e7e7e7;
  -webkit-transition: all ease 250ms;
  transition: all ease 250ms;
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms;
}

aside.active .sidebar .sideText,
aside.active .sidebar .ouletSideText,
aside.active .sidebar .brandDropdown .dropdown-toggle:after {
  opacity: 1;
  visibility: visible;
}

.sidebar {
  padding: 70px 0 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  background-color: transparent;
}

.sidebar .menuList li {
  margin-bottom: 4px;
}

.sidebar .menuList li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 400;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 8px 6px 8px 16px;
  color: #2b2e36;
}

.sidebar .menuList li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: transparent;
  -webkit-transition: all cubic-bezier(0.13, 0.4, 0.71, 0.99) 200ms;
  transition: all cubic-bezier(0.13, 0.4, 0.71, 0.99) 200ms;
}

.sidebar .menuList li > a.active {
  font-weight: 600;
  background-color: #fff0e3;
}

.sidebar .menuList li > a.active:before {
  background-color: #fff0e3;
}

.sidebar .menuList li > a:hover {
  color: #696969;
}

.sidebar .menuList li > a:hover:before {
  background-color: #2b2e36;
}

.sidebar .menuList li > a .menuIcons {
  min-width: 25px;
  margin-right: 7px;
  display: none;
}

.sidebar .menuList li > a .menuIcons img {
  vertical-align: -4px;
}

section.mainSection {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  padding-top: 60px;
  overflow: auto;
  position: relative;
  background-color: #e5e5e5;
}

@media (max-width: 767px) {
  section.mainSection {
    min-width: 600px;
  }
}

.main-in {
  padding: 14px;
}

.main-in .main-div {
  position: relative;
  background-color: white;
  min-height: 80vh;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
  border-radius: 4px;
  padding: 20px;
}

.main-in .main-div.h-auto {
  min-height: auto;
}

.user-manage {
  margin-bottom: 100px;
}

.user-manage .react-multi-carousel-list {
  overflow: visible;
}

.user-manage .btn-grp.flex-center.pt12 {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
}

.blue-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #4d61aa;
  color: white;
  padding: 21px 20px;
  margin: -20px -20px 24px;
}

.blue-heading .title h6 {
  color: white;
  margin-bottom: 0;
}

.col-form .form-in {
  margin-bottom: 16px;
}

.heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 24px;
}

.heading .title {
  padding-right: 24px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

@media (min-width: 1300px) {
  .heading .title h6 {
    font-size: 20px;
  }
}

.heading .title h1,
.heading .title h2,
.heading .title h3,
.heading .title h4,
.heading .title h5,
.heading .title h6 {
  margin-bottom: 0px;
  font-weight: 600;
}

.heading.bb {
  border-bottom: 0.5px solid rgba(80, 80, 80, 0.3);
  padding-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

.flex-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.search-filter {
  position: relative;
  padding: 0 12px 8px;
  margin: 0 -20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.search-filter .form-in {
  margin-bottom: 12px;
}

@media (min-width: 1300px) {
  .search-filter .form-in {
    margin-bottom: 0;
  }
}

.search-filter:not(:last-child) {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 24px;
}

.search-filter .row .flex-0 {
  padding: 0 10px 16px;
}

.search-filter .cols {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 16%;
          flex: 1 1 16%;
  padding: 0 10px 16px;
}

.search-filter.bb0 {
  border-bottom: none;
}

.user-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -15px;
}

.user-info.no-grow li {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.user-info li {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 33.33%;
          flex: 1 0 33.33%;
  padding: 0 15px 24px;
  margin-bottom: 0;
}

.user-info li small {
  color: #696969;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  display: block;
}

.user-info li p {
  font-size: 14px;
  color: #4d61aa;
  font-weight: 600;
  word-break: break-word;
}

.membership-div .user-info li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33%;
          flex: 0 0 33%;
  margin-bottom: 0;
}

.membership-div.reff-list .user-info {
  margin: 0 -12px;
}

.membership-div.reff-list .user-info li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  min-width: 120px;
  padding: 0 12px 16px;
}

.center-btn .table-btns,
.center-btn .table-btns {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.table-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.table-btns button:not(.btn) {
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-transition: all ease 160ms;
  transition: all ease 160ms;
}

.table-btns button.small {
  padding: 6px 14px;
  margin-left: 4px;
}

.table-btns button:hover {
  opacity: 0.7;
}

.status-infom > div {
  padding-top: 24px;
  border-top: 1px solid #d3d3d3;
}

.status-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 16px;
}

.status-info > div {
  padding: 20px 0 10px;
  width: 290px;
  margin-right: 16px;
  border-top: 1px solid #d3d3d3;
}

.status-info > div .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.status-info > div .text > div:not(:last-child) {
  margin-bottom: 12px;
}

.status-info > div h6 {
  font-size: 16px;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

.status-info > div small {
  font-size: 12px;
  color: #696969;
}

.status-info > div p {
  font-size: 14px;
  color: #4d61aa;
  font-weight: 600;
}

.member-info {
  position: relative;
  background-color: #f9f4fa;
  padding: 16px 20px;
}

.react-multi-carousel-list {
  margin: 0 -20px;
  padding: 0 0px;
}

.react-multi-carousel-list .recp-info {
  margin: 0 -10px 20px -20px;
}

.react-multi-carousel-list .react-multi-carousel-track > li {
  padding: 0 20px;
}

.membership-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px 24px;
}

.membership-row > div {
  width: 50%;
  padding: 0 10px;
}

.membership-row.gift-memb {
  margin: 0 -20px 24px;
  background: rgba(240, 242, 241, 0.4);
  padding: 0px 12px 18px;
}

.membership-row.gift-memb .membership-div {
  padding-top: 16px;
}

.recp-info {
  background: rgba(240, 242, 241, 0.4);
  padding: 15px 20px;
  margin: 0 -20px 20px;
}

.recp-info.lower .user-info {
  max-width: 600px;
}

.recp-info.lower .user-info li {
  max-width: 33.33%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 33.33%;
          flex: 1 1 33.33%;
}

.recp-info .btns-lower {
  padding-top: 10px;
  padding-bottom: 10px;
}

.recp-info .user-info li {
  -webkit-box-flex: unset;
      -ms-flex: unset;
          flex: unset;
  padding-bottom: 10px;
}

.recp-info .user-info li:nth-child(1), .recp-info .user-info li:nth-child(2) {
  word-break: normal;
}

.recp-info .user-info li:first-child {
  min-width: 200px;
}

.full {
  margin-left: -20px;
  margin-right: -20px;
}

.table-sec {
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.table-sec::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.table-sec::-webkit-scrollbar-track {
  background: transparent;
}

.table-sec::-webkit-scrollbar-thumb {
  background: transparent;
}

.table-sec::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.content-table table tr td:not(:nth-child(1)),
.content-table table tr th:not(:nth-child(1)) {
  text-align: center;
}

.content-table table tr td:nth-child(2),
.content-table table tr th:nth-child(2) {
  text-align: left;
}

.rev-table tr td:not(:first-child), .rev-table tr th:not(:first-child) {
  text-align: center;
  width: 200px;
}

.rev-table tr td:not(:first-child) td, .rev-table tr th:not(:first-child) td {
  text-align: center;
}

.rev-table tr td:first-child, .rev-table tr th:first-child {
  width: 400px;
}

.count th:first-child {
  width: 76px;
}

table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 24px;
}

table th,
table .MuiTableCell-head {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  color: #2b2e36;
  padding: 18px 14px;
  background-color: #fafafb;
  border: none;
  text-align: left;
  width: auto;
  font-family: "Open Sans", sans-serif;
}

table tr:nth-child(odd) td,
table tr:nth-child(odd) .MuiTableCell-bo {
  background-color: #ededed;
}

table td,
table .MuiTableCell-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: #2b2e36;
  padding: 18px 14px;
  border: none;
  background-color: #f8f8f8;
  height: auto !important;
  min-height: auto;
  width: auto;
  font-family: "Open Sans", sans-serif;
}

.yellow-line tr:last-child td {
  border-top: 1px solid #f1ddcc;
}

.white-table table tr th,
.white-table table tr td {
  background-color: white;
  padding: 4px;
}

.light-table.center-right td:not(:first-child), .light-table.center-right th:not(:first-child) {
  text-align: center;
}

.light-table table tr th {
  background-color: white;
}

.light-table table tr td {
  background-color: white;
}

.light-table table tr:nth-child(odd) td {
  background-color: #f8f8f8;
}

.light-table .total td {
  border-bottom: 1px solid #999898;
  border-top: 1px solid #f1ddcc;
}

.grey-table.center td, .grey-table.center th {
  text-align: center;
}

.grey-table table tbody tr th {
  background-color: #f8f8f8;
}

.grey-table table tbody tr td {
  background-color: #f8f8f8;
}

.grey-table table tbody tr:nth-child(odd) td {
  background-color: white;
}

.grey-table thead tr td {
  background-color: #f8f8f8;
}

.grey-table .total td {
  border-bottom: 1px solid #999898;
  border-top: 1px solid #f1ddcc;
}

.lite-table table tr th {
  padding: 16px 14px;
  background-color: white;
}

.lite-table table tr td {
  padding: 9px 14px;
  background-color: white;
}

.lite-table table tr:nth-child(odd) td {
  background-color: #f8f8f8;
}

.editable.min {
  min-width: 80px;
}

.editable br {
  display: none;
}

.editable .MuiInput-root {
  width: auto;
}

.editable input {
  padding: 6px;
  color: #13af28;
  display: inline-block;
  min-width: auto;
  border: 1px solid #c7c7c7 !important;
  width: 100%;
}

.editable.phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.editable.phone .MuiInputBase-input {
  border-left: none !important;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
  height: 31px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.editable.phone .MuiFormControl-root {
  height: auto;
}

.editable.phone .react-tel-input {
  width: auto;
}

.editable.phone .react-tel-input .flag-dropdown {
  height: 31px;
  background-color: transparent;
  border: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
}

.editable.phone .react-tel-input .flag-dropdown li {
  font-size: 14px;
  margin-bottom: 4px;
}

.editable.phone .react-tel-input .flag-dropdown .selected-flag {
  opacity: 0;
  width: 100%;
}

.editable.phone .react-tel-input .form-control {
  line-height: 1;
  height: 31px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-left: 10px;
  max-width: 76px;
  background: url(../images/icons/caret-down-icon.svg) no-repeat;
  background-position: calc(100% - 7px) 13px;
  background-size: 10px;
}

.w-100 {
  width: 100%;
}

.btns-lower {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 24px 12px;
  margin-left: -10px;
  margin-right: -10px;
}

.btns-lower.d-flexx {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.btns-lower .btn-grp {
  text-align: right;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.btns-lower > a,
.btns-lower > button,
.btns-lower > div {
  margin: 0 8px;
}

.customer-edit .form-in {
  margin-bottom: 24px;
}

.login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  background-color: #f1ddcc;
}

.login .wrapper {
  max-width: 512px;
  margin: 0 auto;
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
}

.login .form-in {
  margin-bottom: 24px;
}

.login .form-in label {
  color: #2b2e36;
  font-size: 14px;
}

.login .text {
  background: #ffffff;
  position: relative;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
  border-radius: 20px;
}

.login .text .head {
  text-align: center;
  padding: 48px 24px 0;
}

.login .text .head .logo {
  max-width: 152px;
  margin: 0 auto 10px;
}

.login .text .body {
  padding: 24px 44px;
}

.login .text .body .title {
  text-align: center;
  font-weight: 400;
}

.login .text .foot {
  position: relative;
  text-align: center;
  padding: 0 44px 46px;
}

.login .text .foot .btn.single {
  font-size: 15px;
  padding: 14px 24px;
  min-width: 152px;
}

.link {
  color: #4d61aa;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.MuiPagination-root {
  border-top: 1px solid #eff0f4;
  padding-top: 16px;
}

.MuiPagination-root .MuiPagination-ul {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.MuiPagination-root .MuiPagination-ul li button {
  border: 1px solid #eff0f4;
}

.MuiPagination-root .MuiPagination-ul li button.Mui-selected {
  background-color: #4d61aa;
  color: white;
  border-color: #4d61aa;
}

.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #796f67;
}

.back-btn .icon {
  width: 28px;
  height: 28px;
  background: rgba(0, 0, 0, 0.5);
  border: 0.5px solid #ffffff;
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-right: 6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.main-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.main-loader .logo-pulse span {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: transparent;
  display: inline-block;
  -webkit-animation: rotate 400ms linear infinite forwards;
          animation: rotate 400ms linear infinite forwards;
}

.main-loader .logo-pulse span:nth-child(2) {
  display: none;
}

.main-loader .logo-pulse span:nth-child(3) {
  display: none;
}

.main-loader .logo-pulse span:nth-child(4) {
  display: none;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes wave {
  0%,
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(0, -14px);
            transform: translate(0, -14px);
  }
}

@keyframes wave {
  0%,
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(0, -14px);
            transform: translate(0, -14px);
  }
}

@-webkit-keyframes pulse1 {
  0%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    opacity: 0.7;
  }
}

@keyframes pulse1 {
  0%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    opacity: 0.7;
  }
}

.MuiDialog-paper {
  background-color: transparent !important;
  overflow: visible !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.MuiTypography-root {
  font-family: "Open Sans", sans-serif !important;
}

.social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 380px;
  margin: 0 auto;
}

.social-share div {
  position: relative;
  padding: 0 4px 16px;
  width: 25%;
}

@media (min-width: 767px) {
  .social-share div {
    padding: 0 8px 16px;
    padding-bottom: 24px;
    width: 23%;
  }
}

.social-share div p {
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;
  white-space: nowrap;
}

.share-modal {
  max-width: 526px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.share-modal .close-modal {
  position: absolute;
  right: -10px;
  top: -10px;
  opacity: 0;
}

.share-modal .modal-content {
  background-color: #fff0e3;
  border-radius: 16px;
}

.share-modal .head {
  padding: 24px 24px 0;
}

.share-modal .head h5 {
  margin-bottom: 0px;
}

.share-modal hr {
  opacity: 0.4;
}

.share-modal .body {
  padding-top: 18px;
  padding-bottom: 8px;
}

.share-modal .modal-content {
  max-width: 100%;
}

.compli-modal {
  max-width: 712px;
}

.compli-modal .MuiDialogContent-root {
  padding: 10px 10px 0 10px;
  overflow: hidden;
}

.link-tip {
  position: absolute;
  bottom: 24px;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  white-space: nowrap;
  background-color: #13af28;
  color: white;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  -webkit-box-shadow: 1px 2px 10px 0 rgba(14, 97, 6, 0.28);
          box-shadow: 1px 2px 10px 0 rgba(14, 97, 6, 0.28);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 200ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 200ms;
}

@media (min-width: 767px) {
  .link-tip {
    bottom: 26px;
  }
}

.link-tip.active {
  opacity: 1;
  visibility: visible;
  bottom: 12px;
}

@media (min-width: 767px) {
  .link-tip.active {
    bottom: 20px;
  }
}

.sharing-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #2b2e36;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 180ms;
  cursor: pointer;
}

@media (min-width: 767px) {
  .sharing-icon {
    width: 36px;
    height: 36px;
  }
}

.sharing-icon i {
  width: 12px;
  height: 12px;
  background-size: contain;
  background-position: center;
}

@media (min-width: 767px) {
  .sharing-icon i {
    width: 16px;
    height: 16px;
  }
}

.sharing-icon i.telegram-icon {
  background: url(../images/icons/telegram-icon.svg) no-repeat center;
}

.sharing-icon i.fb-icon {
  background: url(../images/icons/fb-icon.svg) no-repeat center;
}

.sharing-icon i.twitter-icon {
  background: url(../images/icons/twitter1-icon.svg) no-repeat center;
}

.sharing-icon i.linkedin-icon {
  background: url(../images/icons/linked-icon.svg) no-repeat center;
}

.sharing-icon i.whatsapp-icon {
  background: url(../images/icons/whatsapp-icon.svg) no-repeat center;
}

.sharing-icon i.msgr-icon {
  background: url(../images/icons/msgr-icon.svg) no-repeat center;
}

.sharing-icon i.email-icon {
  background: url(../images/icons/email-icon.svg) no-repeat center;
}

.sharing-icon i.link-icon {
  background: url(../images/icons/link-icon.svg) no-repeat center;
  background-size: contain;
}

.sharing-icon:hover {
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.05);
}

.MuiDialog-container {
  overflow: auto;
}

.modal-text {
  position: relative;
  padding: 24px;
  background-color: white;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
  border-radius: 14px;
  min-width: 460px;
}

.modal-text.free-modal {
  min-width: auto;
  width: 100%;
  max-width: 560px;
}

.modal-text .MuiDialogContentText-root p {
  font-size: 16px;
  font-weight: 500;
}

.modal-text .close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
}

.modal-text .close-btn button {
  background: #2b2e36 url(../images/icons/cross-peach-icon.svg) no-repeat center;
  border-radius: 8px;
  color: #f1ddcc;
  font-size: 25px;
  line-height: 1;
  padding: 4px 10px;
  cursor: pointer;
  border: 1px solid #f1ddcc;
  width: 32px;
  height: 32px;
  -webkit-box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
          box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
}

.modal-text .close-btn button span {
  opacity: 0;
}

.modal-text .foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 10px;
}

.modal-text .foot > a,
.modal-text .foot button,
.modal-text .foot div {
  font-weight: 700;
}

.modal-text .foot > a:not(:first-child),
.modal-text .foot button:not(:first-child),
.modal-text .foot div:not(:first-child) {
  margin-left: 10px;
}

.topic-chips label {
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
}

.topic-chips .chip-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -4px;
}

.topic-chips .chip-list .MuiSvgIcon-root {
  -webkit-box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
          box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
  border-radius: 50%;
  padding: 0;
  background: white url(../images/icons/cross-sm-icon.svg) no-repeat center;
  width: 18px;
  height: 18px;
}

.topic-chips .chip-list .MuiSvgIcon-root path {
  fill: white;
}

.topic-chips .MuiChip-root {
  margin-right: 10px !important;
}

.topic-chips li {
  list-style: none;
}

.chip-label {
  position: relative;
  display: inline-block;
  padding: 7px 20px;
  font-weight: 400;
  font-size: 14px;
  background: rgba(43, 46, 54, 0.1);
  border-radius: 38px;
  color: #2b2e36;
}

.chip-label.blue {
  background: rgba(77, 97, 170, 0.1);
  color: #4d61aa;
}

.status-label {
  position: relative;
  display: inline-block;
  padding: 9px 12px;
  font-weight: 600;
  font-size: 12px;
  background: rgba(77, 97, 170, 0.1);
  border-radius: 9px;
  color: #4d61aa;
  min-width: 78px;
  text-align: center;
  white-space: nowrap;
}

.status-label.red {
  background: rgba(255, 59, 59, 0.14);
  color: #fb3449;
}

.status-label.fill-grey {
  background: #d3d3d3;
  color: #696969;
}

.status-label.fill-green {
  background: #5cc928;
  color: white;
}

.status-label.upper {
  text-transform: uppercase;
}

.topic-add .f-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topic-add .f-in input {
  margin-right: 6px;
}

.topic-add .f-in .btn {
  font-size: 30px;
  padding: 4px 14.6px;
  font-weight: 500;
}

.MuiChip-root.MuiChip-filled {
  background: rgba(77, 97, 170, 0.05);
  border-radius: 40px;
}

.MuiChip-root.MuiChip-filled .MuiChip-label {
  color: #4d61aa;
}

.topic-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -20px;
}

.topic-row.header {
  background-color: #fafafb;
}

.topic-row.header .topic-title {
  border-right: none;
}

.topic-row.header .topic-title h5 {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  background-color: transparent;
}

.topic-row.header .th-title {
  width: 100%;
  padding-right: 30px;
}

.topic-row.header table {
  margin-bottom: 0px;
}

.topic-row.header table th {
  font-size: 15px;
  background-color: transparent;
}

.topic-row.header table thead tr th {
  border-top: 0.5px solid #e1e1e1;
}

.topic-row.header table thead tr th:nth-child(1) {
  width: 33%;
}

.topic-row.header table thead tr th:nth-child(2) {
  width: 33%;
  text-align: center;
}

.topic-row.header table thead tr th:nth-child(3) {
  width: 30%;
  text-align: right;
}

.topic-row:last-child .topic-left,
.topic-row:last-child .topic-body {
  border-bottom: 0.5px solid #e1e1e1;
}

.topic-row .topic-left {
  border: 0.5px solid #e1e1e1;
  border-bottom: none;
  width: 300px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.topic-row .topic-left h5 {
  padding: 16px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  background-color: whitesmoke;
}

.topic-row .topic-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
}

.topic-row .topic-title .editable {
  padding-right: 6px;
}

.topic-row .topic-title .editable .MuiInputBase-root {
  width: auto;
}

.topic-row .topic-title .MuiSwitch-root {
  margin: 0 6px 0 auto;
}

.topic-row .topic-title .MuiButtonBase-root {
  padding: 2px;
}

.topic-row .topic-title h6 {
  font-size: 16px;
  color: #4d61aa;
}

.topic-row .topic-title .btn {
  padding: 6px 12px;
  font-size: 13px;
}

.topic-row .topic-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  border-top: 0.5px solid #e1e1e1;
}

.topic-row .topic-body .MuiPaper-root {
  margin: -16px;
}

.topic-row .topic-body .MuiPaper-root .MuiTableRow-root:not(:last-child) td {
  border-bottom: 1px solid #e1e1e1;
}

.topic-row .topic-body .MuiPaper-root tr th {
  font-size: 15px;
  background-color: whitesmoke;
  padding: 16px;
}

.topic-row .topic-body .MuiPaper-root tr td {
  padding: 16px;
}

.topic-row .topic-body table tbody .MuiTableRow-root .MuiTableCell-body:nth-child(1) {
  width: 33%;
}

.topic-row .topic-body table tbody .MuiTableRow-root .MuiTableCell-body:nth-child(2) {
  width: 33%;
  text-align: center;
}

.topic-row .topic-body table tbody .MuiTableRow-root .MuiTableCell-body:nth-child(3) {
  width: 30%;
  text-align: right;
}

.topic-row .MuiPaper-root {
  width: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: 0px;
  text-align: center;
  overflow: visible;
}

.MuiPaper-root:has(.modal-text) {
  max-width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.btn-plus {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  background-color: rgba(77, 97, 170, 0.1);
  border-radius: 4px;
  color: #4d61aa;
  border: none;
  font-size: 24px;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 30px;
}

.no-content {
  padding: 50px 24px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.no-content h6 {
  margin-bottom: 8px;
  font-size: 20px;
}

.no-content p {
  font-size: 16px;
}

.content-type {
  margin-bottom: 30px;
}

.content-type.form-in .MuiFormControl-root {
  width: 100%;
}

.content-type.form-in .MuiFormGroup-root {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.content-type.form-in .MuiFormGroup-root label {
  width: 48%;
  margin-right: 0 !important;
}

.content-type p {
  margin-bottom: 12px;
}

.content-type .MuiFormGroup-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.content-type .MuiFormGroup-root > label {
  padding: 10px 20px 10px 12px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin: 0;
}

.content-type .MuiFormGroup-root > label:has(.Mui-checked) {
  border-color: #4d61aa;
}

.content-type .MuiFormGroup-root > label:not(:last-child) {
  margin-right: 12px;
}

.content-type .MuiFormGroup-root > label .MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  font-family: "Open Sans", sans-serif;
}

.content-type .MuiFormGroup-root > label .MuiRadio-root {
  padding: 0;
}

.dashboard-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 0;
  margin: 0 -10px;
}

.dashboard-cards > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 20%;
          flex: 1 1 20%;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px 10px;
  margin: 0 10px;
  text-align: center;
}

.dashboard-cards > div h5 {
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
}

.dashboard-cards > div p {
  margin-bottom: 16px;
  font-weight: 700;
}

.effective-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -20px;
}

.effective-div > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 50%;
          flex: 1 1 50%;
  padding: 0 20px;
}

.effective-div h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.table-reports > div {
  padding-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.table-reports > div > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.report-analytics .heading:not(.no-sm) .btn, .report-analytics .heading:not(.no-sm) .btn-white, .report-analytics table:not(.no-sm) .btn, .report-analytics table:not(.no-sm) .btn-white {
  font-size: 12px;
  padding: 9px 10px;
  line-height: 1;
}

.report-analytics table .btn, .report-analytics table .btn-white {
  min-width: 100px;
}

.report-analytics > .col-md-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.report-analytics > .col-md-6 .main-div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
}

.report-analytics .date-pick.date-input {
  max-width: 310px;
}

.btn-msg {
  padding: 8.5px;
  background-color: #4d61aa;
  border: none;
  border-radius: 4px;
}

.content-flex h6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #d3d3d3;
  padding-top: 24px;
}

.content-flex p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #676767;
  font-size: 14px;
}

.content-flex p b {
  font-size: 14px;
}

.content-flex p:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
}

.select-option {
  text-align: right;
  padding-bottom: 10px;
  margin: 0 -7px;
}

.select-option > div {
  display: inline-block;
  text-align: left;
  font-size: 14px;
  padding: 0 7px 14px;
  width: auto;
}

.select-option [class*="menu"] {
  font-size: 12px;
  min-width: 100px;
}

.select-option.lg [class*="menu"] {
  font-size: 12px;
  min-width: 160px;
}

.eff-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 400px;
  padding-top: 10px;
}

.eff-inner:not(:last-child) {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 10px;
}

.eff-inner > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.eff-inner > div .recharts-wrapper {
  margin-right: 12px;
}

.eff-inner p:not(.num) {
  line-height: 1.2;
  font-weight: 400;
  font-size: 14px;
  color: #696969;
}

.eff-inner p.num {
  font-size: 20px;
  font-weight: 700;
}

.publish-date {
  max-width: 500px;
  margin-bottom: 30px;
}

.publish-date .form-in {
  position: relative;
  z-index: 10;
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.23);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.spinner span {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 4px solid white;
  border-top-color: transparent;
  display: inline-block;
  -webkit-animation: rotate 400ms linear infinite forwards;
          animation: rotate 400ms linear infinite forwards;
}

.loader-svg {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.logo-pulse .svg-elem-1 {
  stroke-dashoffset: 99.40499114990234px;
  stroke-dasharray: 99.40499114990234px;
  stroke: white;
  -webkit-animation: load1 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite forwards;
          animation: load1 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite forwards;
}

.logo-pulse .svg-elem-2 {
  stroke-dashoffset: 122.0291976928711px;
  stroke-dasharray: 122.0291976928711px;
  fill: transparent;
  -webkit-animation: loadd 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite forwards;
          animation: loadd 1.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite forwards;
}

@-webkit-keyframes load1 {
  0% {
    stroke-dashoffset: 99.40499114990234px;
  }
  20% {
    stroke-dashoffset: 99.40499114990234px;
  }
  80% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@keyframes load1 {
  0% {
    stroke-dashoffset: 99.40499114990234px;
  }
  20% {
    stroke-dashoffset: 99.40499114990234px;
  }
  80% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes loadd {
  0% {
    stroke-dashoffset: 122.0291976928711px;
    fill: transparent;
  }
  70% {
    stroke-dashoffset: 0;
    fill: white;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

@keyframes loadd {
  0% {
    stroke-dashoffset: 122.0291976928711px;
    fill: transparent;
  }
  70% {
    stroke-dashoffset: 0;
    fill: white;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    stroke-dashoffset: 0;
  }
}

.img-upload {
  position: relative;
  background: #f8f8f8;
  border: 1px dashed #d3d3d3;
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 4/1.202;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.img-upload.uploaded .text {
  opacity: 0;
  visibility: hidden;
}

.img-upload input {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.img-upload .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.img-upload .text .accept-img {
  font-size: 12px;
  color: #696969;
  padding-top: 4px;
}

.img-upload .text .icon {
  margin-bottom: 12px;
}

.img-upload img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 6px;
}

.delete-img {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.delete-img button {
  color: transparent;
  background: #696969 url(../images/icons/delete-white-icon.svg) no-repeat center;
  border: 0.5px solid #ffffff;
  border-radius: 6px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.delete-btn {
  position: relative;
  z-index: 10;
}

.delete-btn button {
  color: transparent;
  background: #696969 url(../images/icons/delete-white-icon.svg) no-repeat center;
  border: 0.5px solid #ffffff;
  border-radius: 6px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.multi-select [class*="control"] [class*="multiValue"] {
  background: rgba(77, 97, 170, 0.05);
  border-radius: 40px;
  padding: 8px 4px 8px 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.multi-select [class*="control"] [class*="multiValue"] > div {
  color: #4d61aa;
  padding: 0px;
}

.multi-select [class*="control"] [class*="multiValue"] > div:nth-child(2) {
  border-radius: 50%;
  background-color: white;
  width: 18px;
  height: 18px;
  -webkit-box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
          box-shadow: 0px 4px 8px rgba(19, 19, 19, 0.17);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 6px;
  color: #2b2e36;
  cursor: pointer;
}

.multi-select [class*="control"] [class*="multiValue"] > div:nth-child(2):hover {
  color: #2b2e36;
}

.mobile-banner {
  margin-top: 130px;
}

@media (min-width: 1300px) {
  .mobile-banner {
    margin-top: -94px;
  }
}

.mobile-banner .img-upload {
  max-width: 250px;
  aspect-ratio: 9/15.95;
}

.icon-upload {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.15);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.content-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 -20px 32px;
}

@media (min-width: 1300px) {
  .content-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.content-row > div {
  padding: 0 20px;
  min-width: 400px;
}

.content-row .cols {
  max-width: 720px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0;
          flex: 1 0;
}

@media (min-width: 1300px) {
  .content-row .cols {
    max-width: 60%;
  }
}

.content-piece {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
}

.content-piece figure {
  width: 346px;
  height: 534px;
}

.content-piece .form-in {
  margin-bottom: 24px;
}

.content-img {
  width: 346px;
}

.content-img .img-upload {
  aspect-ratio: 4/5;
}

.main-div.content-btns {
  min-height: auto;
  padding: 18px 24px;
}

.main-div.content-btns .btns-lower {
  padding: 0;
}

.in-div {
  position: relative;
  padding: 20px 20px;
  background: #FFFFFF;
  border: 1px solid rgba(96, 93, 93, 0.4);
  border-radius: 8px;
}

.in-div:not(:last-child) {
  margin-bottom: 24px;
}

.fb-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.web-preview {
  border: 2px solid #d3d3d3;
  padding: 12px;
}

.preview-div {
  position: relative;
  margin-top: 24px;
}

.preview-div.mobile .mobile-div {
  max-width: 401px;
  margin: 0 auto;
  border: 2px solid #d3d3d3;
  padding: 12px;
}

.preview-div.mobile .cards-row {
  margin: 0 !important;
}

.preview-div.mobile .cards-row > div {
  width: 100%;
  padding: 0 0 24px;
}

@media (min-width: 767px) {
  .preview-div.mobile .cards-row > div {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
            flex: 1 1 100%;
    max-width: 100%;
  }
}

.preview-div.mobile .cards-row > div .article-card {
  max-width: 100%;
  border-radius: 0;
}

.preview-div.mobile .article-card {
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 4px;
  min-height: auto;
}

.preview-div.mobile .article-card figure {
  position: relative;
  height: auto;
  padding-bottom: 122%;
}

.preview-div.mobile .article-card figure img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: contain;
     object-fit: contain;
}

.preview-div.mobile .article-card footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px 0 8px;
}

@media (max-width: 767px) {
  .preview-div.mobile .article-card footer {
    padding-bottom: 20px;
  }
}

.preview-div.mobile .article-card footer .blank-btn {
  background-color: transparent;
  font-size: 14px;
  width: auto;
  padding-right: 16px;
  text-transform: uppercase;
}

.preview-div.mobile .article-card footer .blank-btn:after {
  display: none;
}

.preview-div.mobile .article-card footer .blank-btn.listen {
  background-color: #4d61aa;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 12px 5px 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.preview-div.mobile .article-card footer .blank-btn.listen .right-caret-icon {
  margin-left: 4px;
  -webkit-filter: none;
          filter: none;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.preview-div.mobile .article-card .text {
  min-height: auto;
  background-color: white;
  -webkit-box-shadow: 0px -6px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px -6px 8px rgba(0, 0, 0, 0.1);
}

.preview-div.mobile .article-card .text small {
  font-size: 10px;
}

@media (min-width: 400px) and (min-height: 700px) and (max-height: 750px) {
  .preview-div.mobile .article-card .text {
    padding-bottom: 12px;
  }
  .preview-div.mobile .article-card .text p {
    margin-bottom: 2px;
  }
}

.preview-div.mobile .article-card .text p {
  min-height: 100px;
}

@media (min-width: 400px) and (min-height: 800px) and (max-width: 500px) {
  .preview-div.mobile .article-card .text p {
    font-size: 14px;
  }
}

.preview-div.mobile .preview-banner figure {
  width: 100%;
  aspect-ratio: 9/16;
}

.preview-banner figure {
  position: relative;
  width: 100%;
  padding-bottom: 30%;
  background-color: whitesmoke;
}

.preview-banner figure img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.cards-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 30px auto;
  max-width: 1230px;
}

@media (min-width: 767px) {
  .cards-row {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (min-width: 992px) {
  .cards-row {
    margin: 30px -10px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (min-width: 1200px) {
  .cards-row {
    margin: 30px auto;
  }
}

.cards-row > div {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  max-width: 100%;
  padding: 0 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 480px) {
  .cards-row > div {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 50%;
            flex: 0 1 50%;
  }
}

@media (min-width: 767px) {
  .cards-row > div {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 45%;
            flex: 0 1 45%;
  }
}

@media (min-width: 992px) {
  .cards-row > div {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.333%;
            flex: 1 1 33.333%;
    max-width: 33.333%;
    padding: 0 10px 60px;
  }
}

@media (min-width: 1200px) {
  .cards-row > div {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 33.333%;
            flex: 1 1 33.333%;
    max-width: 410px;
    padding: 0 10px 60px;
  }
}

@media (min-width: 1300px) {
  .cards-row > div {
    padding: 0 30px 60px;
  }
}

.article-card {
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 4px 5px 10px rgba(19, 19, 19, 0.08), inset 0px 0px 0px 1px #dfcbba;
          box-shadow: 4px 5px 10px rgba(19, 19, 19, 0.08), inset 0px 0px 0px 1px #dfcbba;
  border: 1px solid #d3d3d3;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 300ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 300ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

@media (min-width: 480px) {
  .article-card {
    border-radius: 8px;
    max-width: 350px;
  }
}

.article-card > a {
  display: none;
}

.article-card > a:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.article-card header {
  background-color: #f1ddcc;
  padding: 14px 10px;
  text-align: center;
}

.article-card header p {
  font-weight: 400;
  color: #2b2e36;
  font-family: "Old Standard", serif;
  line-height: 1;
  text-transform: capitalize;
  font-size: 22px;
}

.article-card figure {
  position: relative;
  background-color: #c5c5c5;
  aspect-ratio: 4/5;
}

.article-card figure span {
  position: absolute !important;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
}

.article-card figure img {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}

.article-card .text {
  position: relative;
  z-index: 2;
  padding: 20px 16px 24px;
  background-color: white;
  border-radius: 30px 30px 0 0;
  margin-top: -28px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

@media (min-width: 480px) {
  .article-card .text {
    background-color: #f9f4fa;
  }
}

.article-card .text p {
  font-size: 14px;
  color: #2b2e36;
  font-weight: 400;
  opacity: 0.7;
  line-height: 1.38;
  min-height: 100px;
}

@media (min-width: 767px) {
  .article-card .text p {
    min-height: 118px;
  }
}

@media (min-width: 1200px) {
  .article-card .text p {
    font-size: 14px;
  }
}

.article-card .text .labels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
}

@media (max-width: 380px) and (max-height: 700px) {
  .article-card .text .labels {
    margin-bottom: 12px;
  }
}

.article-card .text .labels mark {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #fff0e3;
  font-size: 12px;
  font-weight: 700;
}

@media (min-width: 767px) {
  .article-card .text .labels mark {
    font-size: 12px;
  }
}

.article-card .text .labels small {
  font-size: 10px;
  font-style: italic;
  color: #696969;
  text-transform: uppercase;
}

.article-card footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 5;
  margin-top: auto;
}

.article-card footer button {
  position: relative;
  width: 50%;
  text-align: center;
  background-color: #e6e6e6;
  padding: 9px 8px;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 200ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 200ms;
}

@media (min-width: 767px) {
  .article-card footer button:not(:last-child):after {
    content: "";
    position: absolute;
    display: inline-block;
    right: -0.5px;
    top: 4px;
    bottom: 4px;
    border-right: 1px solid #d2d2d2;
    z-index: 2;
  }
}

.article-card footer button i {
  -webkit-filter: grayscale(1) brightness(0.8);
          filter: grayscale(1) brightness(0.8);
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 200ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 200ms;
}

.article-card footer button:hover {
  background-color: #f2f2f2;
}

.article-card footer button:hover i {
  -webkit-filter: grayscale(0) brightness(1);
          filter: grayscale(0) brightness(1);
}

.category-table tr th:nth-child(3), .category-table tr th:last-child,
.category-table tr td:nth-child(3),
.category-table tr td:last-child {
  text-align: center;
}

.mr-10 {
  padding-left: 10px;
}

.z10 {
  z-index: 20;
}

.date-pick {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.date-pick .sep {
  margin: 0 10px;
}

.sub-admin-search {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  max-width: 395px;
}

.sub-admin-search input {
  border-color: #d3d3d3;
}

.rate-div:not(:last-child) {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px;
}

.rate-title {
  font-weight: 600;
  font-size: 16px;
}

.rate-title span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f7f7f7;
  border-radius: 6px;
  padding: 8px 16px;
}

.rate-title span b {
  margin-left: 4px;
  margin-right: 4px;
  font-weight: inherit;
}

.rate-box {
  padding: 20px 0 10px;
  position: relative;
  max-width: 220px;
}

.rate-box .MuiSwitch-root {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.rate-box .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 0.5px solid #d3d3d3;
  padding-bottom: 10px;
  margin-bottom: 16px;
}

.rate-box .head h6 {
  margin-bottom: 0px;
  font-size: 14px;
}

.rate-box .user-info {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 0;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  row-gap: 10px;
}

.rate-box .user-info li {
  -webkit-box-flex: unset;
      -ms-flex: unset;
          flex: unset;
  padding: 0 0;
}

.Toastify__toast-container.toast-boxes {
  padding: 0;
  width: auto;
}

.Toastify__toast-container.toast-boxes .Toastify__toast {
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}

.Toastify__toast-container.toast-boxes .Toastify__toast .Toastify__toast-icon {
  display: none;
}

.Toastify__toast-container.toast-boxes .Toastify__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.tn-box {
  position: relative;
  margin-bottom: 16px;
  background: #fff0e3;
  -webkit-box-shadow: 4px 4px 12px rgba(6, 6, 6, 0.14);
          box-shadow: 4px 4px 12px rgba(6, 6, 6, 0.14);
  border-radius: 15px;
  padding: 18px;
  width: 312px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-transition: all cubic-bezier(0, 0, 0.2, 1) 170ms;
  transition: all cubic-bezier(0, 0, 0.2, 1) 170ms;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.tn-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0;
  height: 30px;
  border-bottom: 6px solid transparent;
  border-bottom-color: #5cc928;
  border-radius: 60px;
}

.tn-box:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 16px;
  z-index: 1;
  background-color: #fff0e3;
  -webkit-animation: progress-line 5s linear forwards;
          animation: progress-line 5s linear forwards;
}

.tn-box:hover:before {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes progress-line {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}

@keyframes progress-line {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}

.tn-box.active {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}

.tn-box.red {
  border-color: #ff484d;
}

.tn-box.red:after {
  border-bottom-color: #ff484d;
}

.tn-box .text {
  padding-left: 12px;
}

.tn-box h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.tn-box p {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.52;
}

.tn-box figure {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #2b2e36;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.emailer-div {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

.emailer-div:not(:last-child) {
  border-bottom: 1px solid silver;
  padding-bottom: 32px;
}

.emailer-div .img-upload {
  width: 200px;
  height: 250px;
  aspect-ratio: auto;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  text-align: center;
}

.emailer-div > .text {
  padding-left: 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.email-preview .emailer-table {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  background-color: #f8f4fa;
  border: none;
  border-spacing: 0;
}

.email-preview .emailer-table.mobile {
  max-width: 400px;
}

.email-preview .emailer-table.mobile .emailer-cards {
  text-align: center;
}

.email-preview .emailer-table.mobile .emailer-cards > div {
  padding-bottom: 6px;
}

.email-preview .emailer-table table {
  margin-bottom: 0;
}

.email-preview .emailer-table tr td {
  background-color: transparent;
  padding: 0;
}

.email-preview .emailer-table .top-td td {
  padding: 20px 15px;
}

.email-preview .emailer-table .top-td td p {
  font-size: 12px;
}

.email-preview .emailer-table .top-td td:last-child {
  text-align: right;
}

.email-preview .emailer-table .footer-td {
  padding: 22px 10px;
  position: relative;
  text-align: center;
  background-color: #f0f2f1;
}

.email-preview .emailer-table .footer-td h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #696969;
  margin-bottom: 16px;
}

.email-preview .emailer-table .footer-td p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  max-width: 351px;
  margin: 0 auto 16px;
  color: #696969;
}

.email-preview .emailer-table .footer-td p a {
  color: #4d61aa;
  text-decoration: underline;
  font-weight: 500;
}

.email-preview .emailer-table .footer-td > a {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #696969;
  text-decoration: none;
}

.email-preview .emailer-table .logo-td {
  text-align: center;
  padding-bottom: 18px;
}

.email-preview .emailer-table .emailer-btn {
  margin-top: 32px;
  text-align: center;
}

.email-preview .emailer-table .emailer-btn button {
  padding: 10px 24px;
  background-color: #f1ddcc;
  -webkit-box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
          box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.19);
  border-radius: 4px;
  border: none;
  font-weight: 600;
  font-size: 10px;
}

.email-cards {
  max-width: 454px;
  margin: 0 auto 30px;
  text-align: center;
}

.email-cards hr {
  margin: 6px 10px 10px;
  background-color: #d3d3d3;
  opacity: 0.3;
}

.emailer-cards {
  display: inline-block;
  padding: 0 0px 26px;
}

.emailer-cards > div {
  width: 150px;
  display: inline-block;
  padding: 20px 10px 0px;
  text-align: center;
}

.emailer-cards img {
  border-radius: 8px;
  width: 100%;
}

.emailer-cards p {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: #616266;
  margin: 4px 0 6px;
  font-style: italic;
}

.emailer-cards small {
  font-family: "Open Sans", sans-serif;
  margin: 0 0 4px;
  font-weight: 700;
  font-size: 10px;
  color: #2b2e36;
  text-transform: uppercase;
  display: block;
}

.emailer-cards a {
  background: #4d61aa;
  -webkit-box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);
          box-shadow: 0px 2.74117px 2.74117px rgba(77, 97, 170, 0.12);
  border-radius: 2px;
  color: white;
  font-weight: 700;
  font-size: 7px;
  padding: 4px 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.emailer-cards a i {
  margin-left: 2px;
  margin-top: -1px;
}

.compli-search .head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: -10px;
}

.compli-search .head p {
  font-size: 16px;
  font-weight: 600;
}

.compli-search .head .btn {
  padding: 10px 10px 20px;
  margin-right: 10px;
}

.compli-search .head .btn i {
  -webkit-filter: invert(1) brightness(2);
          filter: invert(1) brightness(2);
}

.compli-listing {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffffff;
  -webkit-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05), 0px 12px 24px rgba(0, 72, 217, 0.05);
          box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05), 0px 12px 24px rgba(0, 72, 217, 0.05);
  border-radius: 12px;
  padding: 24px 10px 4px;
}

.compli-listing ul {
  position: relative;
  width: 33.333%;
  padding: 0 20px;
}

.compli-listing ul:not(:last-child):after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 20%;
  border-right: 1px solid #d8d8d8;
}

.compli-listing ul li {
  margin-bottom: 16px;
  max-width: 70%;
}

.compli-listing ul li.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 100%;
}

.compli-listing ul li.flex .form-in {
  width: 150px;
}

.compli-listing ul li.flex > div:last-child {
  margin-left: 32px;
}

.compli-listing ul li:last-child {
  margin-bottom: 0;
}

.compli-listing ul li p {
  font-size: 16px;
  color: #2b2e36;
  font-weight: 600;
  margin-bottom: 4px;
}

.compli-listing ul li small {
  font-size: 16px;
  font-weight: 400;
  color: #696969;
}

.compli-listing ul li .form-in label {
  font-size: 16px;
  color: #2b2e36;
  font-weight: 600;
  margin-bottom: 4px;
}

.comp-table tfoot td {
  background-color: transparent;
  border: none;
}

.comp-table.center-right table td:not(:first-child), .comp-table.center-right table th:not(:first-child) {
  text-align: center;
}

.comp-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0 24px 12px;
  margin-bottom: 24px;
  border-bottom: 1px solid #d3d3d3;
}

.comp-search .form-in {
  padding-right: 12px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  max-width: 600px;
}

.reff-in {
  position: relative;
  max-width: 1028px;
  margin: 0 auto;
  background-color: #fff0e3;
  padding: 32px 30px;
}

.reff-in .d-flexx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider-arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.slider-arrows p {
  padding: 0 12px;
}

.slider-arrows button {
  background-color: transparent;
  border: 1.5px solid #373737;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}

.slider-arrows button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.refferal-modal {
  min-width: 712px;
  max-width: 712px;
}

.refferal-modal .MuiDialogContent-root {
  padding: 10px 6px 0;
  overflow: hidden;
}

.refferal-modal .heading {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 14px;
  margin-bottom: 22px;
}

.refferal-modal .user-info li p {
  color: #2b2e36;
}

.refund-modal {
  min-width: 712px;
}

.refund-modal .MuiDialogContent-root {
  padding: 6px 6px;
  overflow: hidden;
}

.refund-modal .heading {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 14px;
  margin-bottom: 22px;
}

.refund-modal .user-info.fifty li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}

.refund-modal .user-info li p {
  color: #2b2e36;
}

.email-modal {
  min-width: 563px;
}

.email-modal .heading {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 18px;
  margin-bottom: 28px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.email-modal .heading h6 {
  text-align: center;
}

.email-modal .MuiDialogContent-root {
  padding: 0;
}

.email-modal .DraftEditor-editorContainer .public-DraftEditor-content {
  min-height: 140px;
  max-width: 600px;
  max-height: 250px;
  overflow: auto;
}

.cancel-details {
  background: #ffffff;
  border: 1px solid #696969;
  border-radius: 8px;
  padding: 24px;
  margin-top: 32px;
}

.cancel-details .btns-lower {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.main-div:has(.refund-div), .main-div:has(.referral-form) {
  margin-bottom: 70px;
}

.referral-form .slider-arrows, .refund-div .slider-arrows {
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  right: 0;
}

.refund-div {
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
}

.refund-sec {
  position: relative;
  background-color: #f9f4fa;
  padding: 32px;
  margin-bottom: 32px;
}

.refund-sec .bb:not(:last-child) {
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 28px;
  margin-bottom: 28px;
}

.refund-sec .f-in label.MuiFormLabel-filled {
  background-color: #f9f4fa !important;
}

.error-page {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.error-page h1 {
  font-size: 68px;
  margin-bottom: 6px;
}

.error-page h4 {
  margin-bottom: 10px;
}

.error-page .icon {
  max-width: 100px;
  margin: 0 auto;
}

.error-page > div {
  background-color: whitesmoke;
  padding: 46px 68px;
  border-radius: 8px;
  margin-bottom: 36px;
}

.slider-over {
  overflow-x: hidden;
}

.slider-over .react-multi-carousel-list {
  overflow: visible;
}

.date-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.count-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
}

.count-cards > div {
  width: 33%;
  padding: 10px;
}

.count-cards .c-card {
  background: #F9F4FA;
  border-radius: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px;
  min-height: 200px;
}

.count-cards .c-card p {
  font-size: 14px;
  margin-bottom: 8px;
}

.count-cards .c-card h6 {
  font-size: 20px;
}

.pie-div {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pie-div > div > h4 {
  text-align: center;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 24px;
}

.pie-div > div p {
  text-align: center;
}

.status-refferal .select-option {
  padding-bottom: 0;
}

.status-refferal .select-option > div {
  padding-bottom: 0;
}

@media (max-width: 1300px) {
  .compli-table table {
    min-width: 1380px;
  }
}

.compli-table table th:nth-last-child(2) {
  min-width: 120px;
}

.sales-table td:first-child {
  width: 400px;
}

.no-pad-select .select-option {
  padding-bottom: 0;
}

.no-pad-select .select-option > div {
  padding-bottom: 0;
}

.label-font-16 {
  font-size: 16px !important;
}

.free-modal .public-DraftEditor-content {
  min-height: 100px;
}

.img-check {
  max-width: 90px;
  margin-bottom: 12px;
}
